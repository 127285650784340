export default {
  receive: "Deposit",
  paymentDateRange: "Deposit Date",
  accountInfo: "Bank Acct Info",
  tablePaymentCount: "Deposit",
  paymentUsageTime: "Period",
  paymentTotalAmount: "Total Amount",
  paymentFee: "MDR",
  paymentRefund: "Total Refunds",
  transferAmount: "Deposited Amount",
  acceptDateRange: "Period",
  acceptCount: "Total Deposit Amount",
  settlementFee: "Merchant Discount Rate (MDR)",
  chargeFee: "VAT",
  refundCount: "Total Refund Amount",
  refundAmount: "Total Refund Amount",
  transferCount: "Transfer Amount",
  transferFee: "Transfer Fee (Including Tax)",
  transferFee2: "Transfer Fee",
  payDate: "Payment Date",
  paymentShopAmount: "Amount",
  freeTax: "※ Some payment brands are tax exempt",
  totalDeductionAmount: "Total deduction amount",
  givePointAmount: "Give Point Amount",
  reviseAmount: "Revise Amount"
};
