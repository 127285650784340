export default {
  userManagement: "ユーザー管理",
  paymentBankAccountSetting: "入金口座情報の設定",
  storeManagement: "店舗管理",
  pointsRules: "店舗管理-ポイントルール",
  enterpriseManagement: "企業情報の設定",
  informationChangeApply: "情報の変更申請をする",
  applyerInfo: "伸入者情报",
  agentInfo: "代表者情報",
  businessType: "業種情報",
  proprietorName: "個人事業主名",
  proprietorNameKana: "個人事業主名（カナ）",
  proprietoChangeApply: "变更申请"
};
