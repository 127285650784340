export default {
  dashboardSubtitle: "Changes in Transaction Amount",
  dashboardNotificationSubtitle: "Marketing Campaigns/Others",
  storeSaleData: "Percentage by Store",
  compareWithLastMonth: "Change % (MOM)",
  franchiseStore: "Store Name",
  comparedAmountToday: "Amount on the same day of last month",
  comparedAmountYestoday: "Amount on the same day of last month",
  comparedAmountCurrentWeek: "Amount on the same week of last month",
  comparedAmountCurrentMonth: "Amount of last month",
  comparedAmountLastMonth: "Amount of two months ago",
  comparedAmountQuarter: "Last year's quarterly amount",
  comparedAmountYear: "Amount of last year"
};
