export default {
  user: "ユーザー",
  userPageTitle: "ユーザー管理",
  merchantManagement: "加盟店管理",
  manageStores: "管轄店舗",
  createNewUser: "新規ユーザーの作成",
  createNewEntUser: "新規加盟店の作成",
  editUser: "ユーザー情報編集",
  passwordRepeat: "パスワード(確認)",
  userDetail: "ユーザー詳細",
  userNouseAndDel: "ユーザー削除",
  userLongTimeNouse: "もう利用する必要がない場合は「削除」してください。",
  superAdmin: "システム管理者",
  administrator: "最高管理者",
  manager: "管理者",
  userRole: "ユーザ",
  meter: "計",
  userDeleteConfirm: "このユーザーを削除しますか？",
  userPasswordChange: "パスワードの変更",
  allshop: "全部店舗",
  noshop: "店舗なし",
  allpermission: "全部権限",
  nopermission: "権限なし",
  userNameInputErr: "「ユーザー名」入力に誤りがあります。",
  userFullNameInputErr: "「名前」入力に誤りがあります。",
  userStausInputErr: "「状態」入力に誤りがあります。",
  userRightInputErr: "「ロール」入力に誤りがあります。",
  manageStoreInputErr: "「管轄店舗」入力に誤りがあります。",
  userContactInputErr: "「連絡先」入力に誤りがあります。",
  userEmailInputErr: "「メール」入力に誤りがあります。",
  userPWDInputErr: "「パスワード」入力に誤りがあります。",
  userPWDReaptInputErr: "「パスワード(確認)」入力に誤りがあります。",
  ForceModifyPassword: "初回ログイン時にパスワードを変更するよう強制されます",
  ForcePasswordExpired: "パスワードを強制的に期限切れにするかどうか",
  PasswordExpirationDays: "パスワードの有効期限",
  FirstLoginModifyPasswordTip: "初回ログインする前にパスワードを変更する必要があります。",
  PasswordExpiredTip: "パスワードの有効期限が切れています。新しいパスワードを設定してください。",
  enableSystemMessage: "StarPay-Works上でネットスターズからの通知を受信する",
  enableSystemEmail: "StarPay-Works上で受信した通知をメールでお知らせする"
};
