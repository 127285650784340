export default {
  receive: "入金",
  paymentDateRange: "入金日",
  accountInfo: "口座情報",
  tablePaymentCount: "{total}件の入金",
  paymentUsageTime: "利用期間",
  paymentTotalAmount: "総利用額",
  paymentFee: "決済手数料",
  paymentRefund: "返金金額",
  transferAmount: "入金金額",
  acceptDateRange: "収納確定期間",
  acceptCount: "収納金合計",
  settlementFee: "決済手数料",
  chargeFee: "消費税",
  refundCount: "返金額合計",
  refundAmount: "返金額合計",
  transferFee: "振込手数料(税込)",
  transferFee2: "振込手数料",
  transferCount: "振込金額",
  payDate: "支払日",
  paymentShopAmount: "利用額計",
  freeTax: "※一部決済ブランドは非課税となります",
  totalDeductionAmount: "控除金額合計",
  givePointAmount: "ポイント進呈代金",
  reviseAmount: "輔正金額"
};
