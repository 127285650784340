export default {
  statisticTimeRange: "集計期間",
  statisticByDateRange: "統計",
  statisticTotalCount: "総件数",
  statisticTotalAmount: "総金額",
  statisticPayCount: "売上総件数",
  statisticPayAmount: "売上総金額",
  statisticRefundCount: "返金総件数",
  statisticRefundAmount: "返金総金額",
  listByDate: "日付順で表示",
  listByCode: "コード別で表示",
  listColTotalCount: "合計件数",
  listColTotalAmount: "合計金額",
  listColPayCount: "売上件数",
  listColPayAmount: "売上金額",
  listColRefundCount: "返金件数",
  listColRefundAmount: "返金金額",
  file1:{
    name:'月次集計',
    head1:'利用期間',
    head2:'企業',
    bodyTitle:'日付明細',
    cell1:'日付',
    cell2:'合計件数',
    cell3:'合計金額',
    cell4:'売上件数',
    cell5:'売上金額',
    cell6:'返金件数',
    cell7:'返金金額',
    cell8:'差引金額',
    cell9:'売上合計',
    cell0:'総合計',
  },
  file2:{
    name:'店舗コード別集計',
    head1:'利用期間',
    head2:'企業',
    bodyTitle:'企業明細',
    cell1:'店舗コード',
    cell2:'店舗名称',
    cell3:'合計件数',
    cell4:'合計金額',
    cell5:'売上件数',
    cell6:'売上金額',
    cell7:'返金件数',
    cell8:'返金金額',
    cell9:'差引金額',
    cella:'売上合計',
    cell0:'総合計',
  },
  file3:{
    name:'企業コード別集計',
    cell1:'企業コード',
    cell2:'企業名称',
 }
}  
