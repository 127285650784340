import http from "../http";

export const login = data => {
  return http({
    method: "post",
    url: `/api/auth/login`,
    data
  });
};

export const logout = () => {
  return http({
    method: "post",
    url: `/api/auth/logout`,
    data: {}
  });
};
export const modifyPassword = data => {
  return http({
    method: "put",
    url: `/api/user/modifypassword`,
    data
  });
};
export const setMFA = data => {
  return http({ method: "put", url: "/api/auth/setmfa", data });
};

export const setMFAWithPassword = data => {
  return http({ method: "put", url: "/api/auth/mfa", data });
};
