export default {
  roleDetail: "Эрхийн бүлгийн дэлгэрэнгүй",
  userRight: "Эрхийн бүлгийн удирдлага",
  newRight: "Шинэ эрхийн бүлэг үүсгэх",
  roleName: "Нэр",
  rightAbility: "Боломжит үйлдлүүд",
  aboutTrade: "Арилжааны тухай",
  aboutTradeSearch: "Арилжааны хайлт",
  aboutTradeDataDownload: "Арилжааны мэдээллийг татаж авах",
  aboutPayment: "Буцаалтын тухай",
  aboutPaymentRefund: "Буцаалт хийх",
  tradeSearch: "Гүйлгээ лавлах",
  tradeDownload: "Гүйлгээний хуулга татах",
  commentEdit: "Тайлбар хэсгийг засварлах",
  receiptSearch: "Орлого хайх",
  receiptDownload: "Орлогын түүх татаж авах",
  userSearch: "Хэрэглэгчийг хайх",
  newUser: "Шинэ хэрэглэгч үүсгэх",
  userEdit: "Хэрэглэгч хэсгийг засварлах",
  userDel: "Хэрэглэгчийг устгах",
  rightGroupSearch: "Эрхийн бүлэг хайх",
  newRightGroup: "Шинэ эрхийн бүлэг үүсгэх",
  rightGroupEdit: "Засварлах",
  rightGroupDel: "Эрхийн бүлэг устгах",
  statistic: "Нэгтгэх",
  statisticDownload: "Мэдээллийг татаж авах",
  saveNewRight: "Үүсгэх",
  PermissionGroup: "Эрхийн бүлэг",
  Permission: "Эрхийн бүлэг хайх",
  PermissionAdd: "Шинэ эрхийн бүлэг үүсгэх",
  PermissionDelete: "Эрхийн бүлэг устгах",
  PermissionEdit: "Эрхийн бүлэг засварлах",
  RecordGroup: "Гүйлгээ",
  Record: "Гүйлгээ лавлах",
  RecordDownload: "Гүйлгээний хуулга татах",
  RefundGroup: "Буцаалт  хийх",
  Refund: "Буцаалт  хийх",
  SettleGroup: "Орлого",
  Settle: "Орлого хайх",
  SettleDownload: "Орлогын түүх татаж авах",
  ShopGroup: "Мерчантийн тохиргоо",
  Shop: "Мерчантын тохиргоо хийх",
  StatGroup: "Ерөнхий мэдээлэл",
  Stat: "Нэгтгэх",
  StatDownload: "Мэдээллийг татаж авах",
  UserGroup: "Хэрэглэгч",
  User: "Хэрэглэгчийг хайх",
  UserAdd: "Шинэ хэрэглэгч үүсгэх",
  UserDelete: "Хэрэглэгчийг устгах",
  UserEdit: "Хэрэглэгч хэсгийг засварлах",
  MessageGroup: "Мэдэгдэл",
  Message: "Хэрэглэгчийг хайх",
  MessageAdd: "Шинэ",
  MessageDelete: "Устгах",
  MessageEdit: "хянан тохиолдуулах",
  DataCompass: "Мэдээллийн луужин",
  PointsReport: "Онцлох мэдээ",
  EntPointsReport: "Хөтөлбөрийн мэдээллийн query"
};
