<template>
  <div id="app" :class="'newStyles ' + fontSize + ' ' + $settings.style" :lang="$store.state.app.language">
    <router-view />
    <div class="chatbotAvatar" v-if="showChatBot" :class="hideChatBot ? 'hide' : ''">
      <img src="./assets/newStyle/chatbot.png" alt="" @click="$window.cbshow()" />
      <div class="hideChatbot" @click="hideChatBot = !hideChatBot"></div>
    </div>
  </div>
</template>
<script>
import { getSessionStorage, getLocalStorage } from "@/utils/storage";
import { getPaymentTypeLogos } from "api/tenant";
export default {
  data() {
    return {
      hideChatBot: false,
      showChatBot: false,
      fontSize: "small"
    };
  },
  mounted() {
    //给全局设定 lang
    document.documentElement.lang = this.$store.state.app.language;

    if (getLocalStorage("fontSize")) {
      this.fontSize = getLocalStorage("fontSize");
    }
    // if (process.env.VUE_APP_GA_ID) {
    //   this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    //   this.$gtag.event("login", { event_category: "login", event_label: this.userInfo.userName });
    // }
    if (this.$settings.chatBot) {
      this.showChatBot = true;
    }

    getPaymentTypeLogos().then(res => {
      console.log("getPaymentLogo:", res);
      if (res.statusCode == 200 && res.resultStatus == "Success") {
        // this.$paymentTypeLogos = res.result;
        this.$store.dispatch("app/setPaymentLogos", res.result);
      }
    });
  },
  computed: {
    baseColor() {
      const element = document.querySelector(".newStyles");
      if (element) {
        return getComputedStyle(element)
          .getPropertyValue("--baseColor")
          .trim();
      }
      return null; // 或者返回一个默认值
    }
  }
};

//该注释代码不要删除
// import { associateFcm, unassociateFcm } from "api/user";
// import { getSessionStorage } from "@/utils/storage";
// export default {
//   created() {
//     let messaging = this.$messaging;
//     messaging
//       .requestPermission()
//       .then(() => {
//         console.log("Notification permission granted.");
//         messaging
//           .getToken({
//             vapidKey: "BH_th3WtOVPGm4_qABeD4jtO9VFWox00E6F-hVscvhO0NQpJmvAe8UjPMcQfR1Fk26abGzN0Ml4mLWSCjV4VCFY"
//           })
//           .then(currentToken => {
//             if (currentToken) {
//               let oldFcmToken = window.localStorage.getItem("oldFcmToken");
//               if (oldFcmToken) {
//                 console.log("FcmToken存在:" + oldFcmToken);
//                 console.log("当前FCM返回token:" + currentToken);
//                 if (oldFcmToken != currentToken) {
//                   console.log("FcmToken变动，刷新FcmToken");
//                   window.localStorage.setItem("isNewFcmToken", "1");
//                   window.localStorage.setItem("fcmToken", currentToken);
//                 }
//               } else {
//                 console.log("FcmToken不存在，保存token");
//                 window.localStorage.setItem("isNewFcmToken", "1");
//                 window.localStorage.setItem("fcmToken", currentToken);
//                 window.localStorage.setItem("oldFcmToken", currentToken);
//               }
//             } else {
//               console.log("No registration token available. Request permission to generate one.");
//             }
//           })
//           .catch(err => {
//             console.log("An error occurred while retrieving token. ", err);
//           });
//       })
//       .catch(err => {
//         console.log("Unable to get permission to notify.", err);
//       });
//     messaging.onMessage(function(payload) {
//       console.log("Message received. ", payload);
//     });
//   }
// };
</script>
