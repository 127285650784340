export default {
  httpFail: "処理失敗",
  noChanged: "変更内容がないため処理がキャンセルされました",
  paramError: "パラメータエラー",
  queryNull: "データなし",
  purviewLack: "権限不足で操作できません",
  illegalOperation: "不正な操作",
  warning: "注意",
  error: "システムエラー",
  ssoError: "SSOエラー",
  refundError: "エラー、お問い合わせください。",
  orderRefunded: "取引が返金済み。返金できません。",
  refundExceed: "「返金金額」が返金可能金額を超えています。",
  refundFeeError: "「返金金額」入力に誤りがあります。",
  refundFail: "エラー、お問合わせください。取引の日時を記録して、お問い合わせください。",
  refundDelay:
    "返金は操作完了しました。クレジットカードの返金は若干のタイムラグの場合がありますので、しばらくお待ちください。",
  refundDelayTips: "クレジットカードの返金は若干のタイムラグの場合がありますので、ご了承ください。",
  incorrectUserInfo: "ユーザーID/パスワード不正",
  userDisadbled: "アカウントがロックされました",
  userNotExist: "アカウントが存在しません",
  userExist: "アカウントがすでに存在しています",
  newNotEqualsOld: "現在のパスワードと異なる必要があります",
  invalidPasswordFormat: "長さ12桁以上、アルファベット大文字小文字、数字と記号を使用してください。",
  invalidOldPasswordFormat: "長さ8桁以上、アルファベット大文字小文字、数字と記号を使用してください。",
  noUserName: "ユーザーIDを入力してください",
  noPassword: "パスワードを入力してください",
  userNameExist: "ユーザー名は既に存在します",
  userEmailExist: "メールが登録されました",
  passwordCanNotContainUserName: "パスワードにユーザー名を含めることはできません",
  entNotExist: "企業コードエラー",
  tradeSearchPeriodLimit: "取引検索期間の範囲は三ヶ月以内に指定してください",
  statisticSearchPeriodLimit: "集計期間の範囲は一ヶ月以内に指定してください。",
  differentPasswordInput: "同じパスワードを入力してください",
  roleNameIsNull: "ロール名を入力してください",
  outTradeNoIsNull: "取引番号を入力してください",
  roleUsed: "使用中ロールのため、削除することはできません。",
  roleExist: "ロールがすでに存在しています。",
  roleNotExist: "ロールが存在しません。",
  nonePermissionSelected: "「権限」選択に誤りがあります。",
  invalidPasswordHistory: "無効なパスワード: 最近の5パスワードのいずれかと同じパスワードは禁止されています。",
  networkError: "サーバーに接続できませんでした。インターネット接続を確認し、もう一度お試しください。",
  uploadFail: "アップロード失敗しました",
  uploadImageSizeLimit: "画像サイズの制限は2M",
  errorRemarkFormat: "100文字まで。",
  remarkIsNull: "備考を入力してください。",
  referenceServiceUnavailable: "申し訳ありませんがサードパーティのサービスは異常となるため使用できません。"
};
