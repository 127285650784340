export default {
  //数据大屏
  tradeDataLabel: "取引データ",
  tradeAmountLabel: "支払金額",
  tradeQuantityLabel: "支払件数",
  refundAmountLabel: "返金額",
  refundQuantityLabel: "返金件数",
  storeScaleTitle: "店舗取引比率チャート",
  storeScaleGraphTitle: "店舗シェア",
  trendGraphTitle0: "本日の取引トレンド",
  trendGraphTitle1: "今週取引トレンド",
  trendGraphTitle2: "今月取引トレンド",
  trendGraphTitle3: "今四半期取引トレンド",
  trendGraphTitle4: "今年取引トレンド",
  allCompanyLabel: "全企業",
  allStoreLabel: "全部",

  //取引日报&&取引月报
  tradeDailyTitle: "取引日报",
  tradeMonthlyTitle: "取引月报",
  tradeSubsidiaryLabel: "子企業",
  tradeCountByCompany: "全店舗統計データ",
  tradeCountByShop: "店舗個別統計データ",
  tradeCountNotByPaymentType: "全決済ブランド統計データ",
  tradeCountByPaymentType: "決済ブランド別統計データ",
  tradeCountTimeLabel: "期間",
  tradeSearchBtn: "検索する",
  tradeDownloadBtn: "データをダウンロード",
  dateColumnLabel: "日時",
  dateMonthColumnLabel: "年月",
  companyCodeColumnLabel: "企業コード",
  companyNameColumnLabel: "企業名称",
  storeCodeColumnLabel: "店舗コード",
  storeNameColumnLabel: "店舗名",
  paymentTypeColumnLabel: "決済種別",
  paymentNumColumnLabel: "支払件数",
  paymentAmountColumnLabel: "支払金額",
  refundNumColumnLabel: "返金件数",
  refundAmountColumnLabel: "返金額",
  deductedAmountColumnLabel: "差引金額",
  tradeDataTitle: "指定した期間の取引データ",
  tradeDataPaymentNum: "支払件数：",
  tradeDataPaymentAmount: "支払金額：",
  tradeDataRefundNum: "返金件数：",
  tradeDataRefundAmount: "返金金額：",
  tradeDataDeductedAmount: "差引金額：",
  todayLabel: "今日",
  weekLabel: "今週",
  monthLabel: "今月",
  lastMonthLabel: "先月",
  monthBeforeLastLabel: "先々月",
  quarterLabel: "今四半期",
  yearLabel: "今年",
  otherLabel: "他の",
  totalValue: "総合計"
};
