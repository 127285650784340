<template>
  <el-header class="app-header clearfix">
    <div class="left-menu">
      <div class="stylesMode">
        <span class="title">{{ $t("fontSize") }}</span>
        <span :class="fontSize == 'large' ? 'btn active' : 'btn'" @click="setFontSize('large')"
          ><a href="" style="font-size: 24px;">{{ $t("large") }}</a></span
        >
        <span :class="fontSize == 'medium' ? 'btn active' : 'btn'" @click="setFontSize('medium')"
          ><a href="" style="font-size: 22px;">{{ $t("medium") }}</a></span
        >
        <span :class="fontSize == 'small' ? 'btn active' : 'btn'" @click="setFontSize('small')"
          ><a href="" style="font-size: 20px;">{{ $t("small") }}</a></span
        >
      </div>
    </div>
    <div class="right-menu">
      <feedback v-if="!isAdmin()"></feedback>
      <el-dropdown class="right-menu-item">
        <div>
          <!-- <svg-icon icon-class="person" class="svg-icon"></svg-icon> -->
          <img class="avatar" src="./../../assets/newStyle/icon_avatar.png" />
          <span class="el-dropdown-link"
            >{{ entShowName }} {{ userInfo.fullName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="showMFADialog"> {{ $t("twoStepAuth") }} </el-dropdown-item>
          <el-dropdown-item @click.native="editPassword"> {{ $t("editPassword") }} </el-dropdown-item>
          <el-dropdown-item @click.native="logoutHandle"> {{ $t("logout") }} </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <language-select />
      <modal-dialog
        v-loading="modifyLoading"
        class="editpassword"
        :show-dialog.sync="showEditPassword"
        :dialog-title="$t('editPassword')"
        :commit-text="$t('savePassword')"
        @closeDialog="modifyLoading = false"
      >
        <el-form ref="form" :model="form" label-position="top" :rules="rules">
          <el-form-item :label="$t('userName')">
            <el-input v-bind:value="userInfo.userName" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('oldPassword')" prop="oldPassword">
            <el-input
              v-model="form.oldPassword"
              type="password"
              clearable
              show-password
              autocomplete="off"
              :placeholder="$t('passwordInputHint')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('newPassword')" prop="newPassword">
            <el-input
              v-model="form.newPassword"
              type="password"
              clearable
              show-password
              autocomplete="off"
              :placeholder="$t('passwordInputHint')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('rePassword')" prop="rePassword">
            <el-input
              v-model="form.rePassword"
              type="password"
              clearable
              show-password
              autocomplete="off"
              :placeholder="$t('passwordInputHint')"
            ></el-input>
            <div v-if="errorMsg" class="error-msg">{{ errorMsg }}</div>
          </el-form-item>
        </el-form>
        <template v-slot:footer>
          <el-button class="cancel" :disabled="modifyLoading" @click="showEditPassword = false">
            {{ $t("cancel") }}
          </el-button>
          <el-button
            class="confirm dialog-btn"
            type="primary"
            @click="saveModifyPassword"
            :loading="modifyLoading"
            :disabled="modifyLoading"
          >
            {{ $t("confirm") }}
          </el-button>
        </template>
      </modal-dialog>
      <!-- mfadialog -->
      <modal-dialog
        class="editpassword"
        :show-dialog.sync="MFADialog"
        :dialog-title="$t('twoStepAuth')"
        :commit-text="$t('savePassword')"
        @commitDialog="saveModifyPassword"
        @closeDialog="
          modifyLoading = false;
          editMFA = false;
        "
      >
        <el-form
          ref="twoStepForm"
          :model="twoStepForm"
          label-position="top"
          :rules="twoStepRules"
          v-loading="modifyLoading"
        >
          <el-form-item :label="$t('userName')" prop="username">
            <!-- {{ $store.state.app.userInfo.userEmail }} -->
            <el-input v-bind:value="userInfo.userName" disabled></el-input>
          </el-form-item>

          <el-form-item :label="$t('password')" prop="password" v-if="!$store.state.app.userInfo.mfa">
            <!-- {{ $store.state.app.userInfo.userEmail }} -->
            <el-input v-model="twoStepForm.password" type="password"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('twoStepEmail')"
            prop="email"
            v-show="$store.state.app.userInfo.userEmail == '' || editMFA"
          >
            <el-input v-model="twoStepForm.email" clearable autocomplete="off" :disabled="modifyLoading"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('confirmTwoStepEmail')"
            prop="confirmEmail"
            v-show="$store.state.app.userInfo.userEmail == '' || editMFA"
          >
            <el-input
              v-model="twoStepForm.confirmEmail"
              clearable
              autocomplete="off"
              :disabled="modifyLoading"
            ></el-input>
            <div v-if="errorMsg" class="error-msg">{{ errorMsg }}</div>
          </el-form-item>
          <el-form-item
            :label="$t('configuredTwoStepEmail')"
            v-if="!($store.state.app.userInfo.userEmail == '' || editMFA)"
          >
            <el-input v-model="$store.state.app.userInfo.userEmail" clearable autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-form>
        <template v-slot:footer>
          <el-button
            class="cancel"
            :disabled="modifyLoading"
            @click="
              MFADialog = false;
              editMFA = false;
            "
          >
            {{ $t("cancel") }}
          </el-button>
          <el-button
            class="confirm dialog-btn"
            type="primary"
            @click="saveTwoStepEmail"
            :loading="modifyLoading"
            :disabled="modifyLoading"
            v-if="$store.state.app.userInfo.userEmail == '' || editMFA"
          >
            {{ $t("confirm") }}
          </el-button>
          <el-button class="confirm dialog-btn" type="primary" @click="showTwoStepEmail" v-else>
            {{ $t("resetMFA") }}
          </el-button>
        </template>
      </modal-dialog>
    </div>
  </el-header>
</template>
<script>
import { resetPassword, getUserInfo } from "api/user";
import { setMFAWithPassword, setMFA, logout } from "api/auth";
import { getSessionStorage } from "@/utils/storage";
import { getLocalStorage, setLocalStorage } from "@/utils/storage";
import { validatePassWord, validateOldPassWord } from "utils";
import { Loading } from "element-ui";

import { isAdmin } from "utils/index";
export default {
  name: "Header",
  data() {
    var validatePass = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      } else {
        callback();
      }
    };
    var validateOldPass = (rule, value, callback) => {
      if (!validateOldPassWord(value)) {
        callback(new Error(this.$t("invalidOldPasswordFormat")));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error(this.$t("differentPasswordInput")));
      } else {
        callback();
      }
    };

    const confirmEmail = (rule, value, callback) => {
      if (this.twoStepForm.email !== this.twoStepForm.confirmEmail) {
        callback(new Error(this.$t("rules.errorConfirmEmail")));
      } else {
        callback();
      }
    };
    return {
      MFADialog: false,
      editMFA: false,
      fontSize: "small",
      modifyLoading: false,
      showEditPassword: false,
      userName: "",
      form: {
        userId: "",
        oldPassword: "",
        newPassword: "",
        rePassword: ""
      },
      twoStepForm: {
        password: "",
        email: "",
        confirmEmail: ""
      },
      userInfo: {},
      entShowName: "",
      errorMsg: "",
      rules: {
        oldPassword: [{ required: true, message: this.$t("noPassword"), trigger: "blur" }],
        newPassword: [
          { required: true, message: this.$t("noPassword"), trigger: "blur" },
          { validator: validatePass, trigger: "blur" }
        ],
        rePassword: [
          { required: true, message: this.$t("noPassword"), trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ]
      },
      twoStepRules: {
        email: [
          { required: true, message: this.$t("rules.requireEmail"), trigger: "blur" },
          {
            required: true,
            type: "email",
            message: this.$t("rules.errorEmail"),
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: this.$t("noPassword"), trigger: "blur" },
          { validator: validateOldPass, trigger: "blur" }
        ],
        confirmEmail: [
          { required: true, message: this.$t("rules.requireEmail"), trigger: "blur" },
          {
            type: "email",
            required: true,
            message: this.$t("rules.errorEmail"),
            trigger: "blur"
          },
          {
            validator: confirmEmail,
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.form.userId = this.userInfo.userId;
    this.entShowName = this.userInfo.entDisplayName;

    console.log("userinfo", this.$store.state.app.userInfo);
    if (this.$store.state.app.userInfo.forceBindMfa) {
      this.showMFADialog();
    }
    // if (!this.$store.state.app.userInfo.mfa) {
    //   this.showMFADialog();
    // }
    if (getLocalStorage("fontSize")) {
      this.fontSize = getLocalStorage("fontSize");
    }
  },
  methods: {
    isAdmin,
    setFontSize(f) {
      this.fontSize = f;
      setLocalStorage("fontSize", f);
      window.location.reload();
    },
    editPassword() {
      this.showEditPassword = true;
    },
    showMFADialog() {
      this.MFADialog = true;
      if (!(this.$store.state.app.userInfo.userEmail == "")) {
        this.twoStepForm.email = this.$store.state.app.userInfo.userEmail;
        this.twoStepForm.confirmEmail = this.$store.state.app.userInfo.userEmail;
      }
      if (!this.$store.state.app.userInfo.mfa) {
        this.editMFA = true;
      }
    },
    showTwoStepEmail() {
      if (this.$store.state.app.userInfo.userEmail == "") {
        this.editMFA = true;
      } else {
        this.twoStepForm.email = this.$store.state.app.userInfo.userEmail;
        this.twoStepForm.confirmEmail = this.$store.state.app.userInfo.userEmail;
        this.editMFA = true;
      }
    },

    async saveModifyPassword() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            ...this.form
          };
          this.modifyLoading = true;
          resetPassword(data).then(res => {
            if (res.resultStatus === "Success" && res.statusCode === 200) {
              this.showEditPassword = false;
              this.$message.success(this.$t("success"));
              this.form.newPassword = "";
              this.form.rePassword = "";
              this.form.oldPassword = "";
              this.logoutHandle();
            }
            this.modifyLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    saveTwoStepEmail() {
      console.log("in saveTwoStep");
      console.log(this.$refs);

      let fn;
      if (!this.$store.state.app.userInfo.mfa) {
        fn = setMFAWithPassword;
      } else {
        fn = setMFA;
      }

      this.$refs.twoStepForm.validate(valid => {
        // console.log(valid);
        // console.log(fn);

        if (valid) {
          let data = {
            ...this.twoStepForm
          };
          data.account = this.userInfo.userName;
          this.modifyLoading = true;
          fn(data)
            .then(res => {
              console.log(res);
              this.modifyLoading = false;

              if (res.resultStatus === "Success" && res.statusCode === 200) {
                this.modifyLoading = true;
                // this.showEditPassword = false;
                this.$message.success(this.$t("twoStepSetSuccess"));
                this.logoutHandle();
                // this.form.email= "";
                // this.form.rePassword = "";
                // await getUserInfo().then(response => {
                //   // loading.close();
                //   if (response.resultStatus === "Success" && response.statusCode === 200) {
                //     let data = response.result;
                //     console.log(response.result);
                //     this.$store.dispatch("app/setAppInfo", data);
                //   } else {
                //     // store.dispatch("app/logout").then(() => {
                //     //   keycloak.logout();
                //     // });
                //   }
                // });
              } else {
                this.modifyLoading = false;
              }
            })
            .cateh(err => {
              this.modifyLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    logoutHandle() {
      const loading = Loading.service({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        fullscreen: true
      });
      logout().then(res => {
        console.log("logout", this.$store.state.app.token, res);
        this.$store.dispatch("app/logout").then(() => {
          this.$keycloak.clearToken();
          this.$keycloak.logout();

          // let a = this.$keycloak.createLogoutUrl();
          // console.log(a);
          loading.close();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.el-dropdown-link {
  cursor: pointer;
  font-size: 16px;
}

.app-header {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 40px;
}

.right-menu {
  float: right;
}

.svg-icon {
  margin-right: 8px;
  margin-left: 8px;
}

.right-menu-item {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.3s all ease;
  line-height: 60px;
}

.el-dropdown-menu {
  top: 40px !important;
}
.app-header .el-form-item {
  line-height: 1;
  margin-bottom: 5px;
}
.error-msg {
  color: #f56c6c;
}
</style>
