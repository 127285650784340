export default {
  QrGroup: "QR Code Management",
  Qr: "Search QR Code",
  EditQr: "Edit QR Code",
  DeleteQr: "Delete QR Code",
  CreateQr: "Create QR Code",
  permission: {
    PermissionGroup: "Role",
    Permission: "Search",
    PermissionAdd: "Create",
    PermissionDelete: "Delete",
    PermissionEdit: "Edit",
    RecordGroup: "Transaction",
    Record: "Search",
    RecordDownload: "Download",
    RefundGroup: "Refund",
    Refund: "Refund",
    SettleGroup: "Deposit",
    Settle: "Search",
    SettleDownload: "Download",
    ShopGroup: "Store",
    Shop: "Store Manage",
    StatGroup: "Aggregate",
    Stat: "Search",
    StatDownload: "Download",
    UserGroup: "User",
    User: "Search",
    UserAdd: "Create",
    UserDelete: "Delete",
    UserEdit: "Edit",
    MessageGroup: "Message",
    Message: "Search",
    MessageAdd: "Create",
    MessageDelete: "Delete",
    MessageEdit: "Edit",
    DataCompass: "Data Compass",
    PointsReport: "Points Report",
    EntPointsReport: "Enterprise Data Query",
    AdminPermissionGroup: "Administrator Management",
    AdminPermission: "Search",
    AdminPermissionAdd: "Create",
    AdminPermissionEdit: "Edit",
    AdminPermissionDelete: "Delete",
    EntGroup: "Merchant Management",
    EntUser: "Search",
    EntUserAdd: "Create",
    EntUserEdit: "Edit",
    EntUserDelete: "Delete",
    ManualGroup: "User Manual Management",
    Maunal: "User Manual Management",
    ManualType: "Search category",
    ManualTypeAdd: "Add category",
    ManualTypeEdit: "Edit category",
    ManualTypeDelete: "Delete category",
    ManualRecordAdd: "Add manual",
    ManualRecordEdit: "Edit manual",
    ManualRecordDelete: "Delete manual"
  }
};
