import http from "../http";

export const getTenants = params => {
  return http({
    method: "get",
    url: "/api/tenant/gettenants",
    params
  });
};
export const delTenant = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deletetenant",
    data
  });
};

export const createTenant = data => {
  return http({
    method: "post",
    url: "/api/tenant/createtenant",
    data
  });
};

export const updateTenant = data => {
  return http({
    method: "put",
    url: "/api/tenant/updatetenant",
    data
  });
};

export const getTenantConfigType = params => {
  return http({
    method: "get",
    url: "/api/tenant/gettenantconfigtypes",
    params
  });
};

export const createTenantConfigType = data => {
  return http({
    method: "post",
    url: "/api/tenant/createtenantconfigtype",
    data
  });
};

export const deleteTenantConfigType = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deletetenantconfigtype",
    data
  });
};

export const updateTenantConfigType = data => {
  return http({
    method: "put",
    url: "/api/tenant/updatetenantconfigtype",
    data
  });
};

export const getTenantConfigs = params => {
  return http({
    method: "get",
    url: "/api/tenant/gettenantconfigs",
    params
  });
};

// 添加TenantConfig的函数
export const createTenantConfig = data => {
  return http({
    method: "post",
    url: "/api/tenant/createtenantconfig",
    data
  });
};

// 删除TenantConfig的函数
export const deleteTenantConfig = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deletetenantconfig",
    data
  });
};

// 修改TenantConfig的函数
export const updateTenantConfig = data => {
  return http({
    method: "put",
    url: "/api/tenant/updatetenantconfig",
    data
  });
};

export const getCreditCardConfigs = params => {
  return http({
    method: "get",
    url: "/api/tenant/getentcreditcardconfigs",
    params
  });
};

export const updateCreditCardConfig = data => {
  return http({
    method: "put",
    url: "/api/tenant/updateentcreditcardconfig",
    data
  });
};

export const deleteCreditCardConfig = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deleteentcreditcardconfig",
    data
  });
};

export const createCreditCardConfig = data => {
  return http({
    method: "post",
    url: "/api/tenant/createentcreditcardconfig",
    data
  });
};

export const getPaymentTypeLogos = params => {
  return http({
    method: "get",
    url: "/api/tenant/getpaymenttypelogos",
    params
  });
};

export const updatePaymentTypeLogo = data => {
  return http({
    method: "put",
    url: "/api/tenant/updatepaymenttypelogo",
    data
  });
};

export const deletePaymentTypeLogo = data => {
  return http({
    method: "delete",
    url: "/api/tenant/deletepaymenttypelogo",
    data
  });
};

export const uploadPaymentTypeLogo = data => {
  return http({
    method: "post",
    url: "/api/tenant/uploadpaymenttypelogo",
    data,
    headers: {
      "Content-Type": "multipart/form-data;"
    }
  });
};
