export default {
  QRManage: "QRコード管理",
  createQRCode: "新規QRコード",
  QRCode: "QRコード",
  amount: "固定価格",
  notFixedAmount: "非固定",
  isFixedAmount: "固定",
  color: "カラー",
  logoTips: `画像の推奨サイズ：100px、横100px
  拡張子：jpeg/jpg/png（容量：1MBまで）`,
  logoColorTips: `スキャンの成功率に緊がる為、濃い目の色がお勧めします。
  (白、グレーなど薄めの色がスキャンできません。)`,
  delQRCodeTips: "QRコードを削除しますか？",
  size: "サイズ",
  downloadQRCode: "決済用QRコードをダウンロード",
  storeIdInputErr: "「店舗名」入力に誤りがあります。",
  amountInputErr: "「価格」入力に誤りがあります。",
  uploadSizeErr: "アップロードされたファイルに誤りがあります。"
};
