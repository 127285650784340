export default {
  userManagement: "Хэрэглэгчийн удирлдлага",
  paymentBankAccountSetting: "Орлогын дансны мэдээллийн тохиргоо",
  storeManagement: "Мерчантын тохиргоо хийх",
  enterpriseManagement: "Байгууллагын мэдээллийн тохиргоо",
  informationChangeApply: "Мэдээлэл өөрчлөх хүсэлт илгээх",
  applyerInfo: "Хүсэлт илгээгчийн мэдээлэл",
  agentInfo: "Төлөөлөгчийн мэдээлэл",
  businessType: "Бизнесийн төрлийн мэдээлэл",
  proprietorName: "Хувиараа бизнес эрхлэгчийн нэр",
  proprietorNameKana: "Хувиараа бизнес эрхлэгчийн нэр /Катакана үсгээр/ ",
  proprietoChangeApply: "Өөрчлөх хүсэлт илгээх"
};
