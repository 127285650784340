export default {
  dashboardSubtitle: "Гүйлгээний мэдээлэл",
  dashboardNotificationSubtitle: "Урамшууллын аян, бусад",
  storeSaleData: "Дэлгүүр бүрийн борлуулалт",
  compareWithLastMonth: "Өмнөх сартай харьцуулалт",
  franchiseStore: "Мерчант",
  comparedAmountYear: "Өнгөрсөн жилд төгрөгийн хэмжээ",
  comparedAmountQuarter: "Өнгөрсөн жилдийн улиралд төгрөгийн хэмжээнүүд",
  comparedAmountLastMonth: "2 сарын өмнөх дүн",
  comparedAmountCurrentMonth: "Өнө сарын дүн",
  comparedAmountCurrentWeek: "Өнө сарын тухайн долоо хоногтой өнөөдөрх дүн",
  comparedAmountYestoday: "Өнө сарын тухайн өдөртэй ижил дүн",
  comparedAmountToday: "Өнө сарын тухайн өдөртэй ижил дүн"
};
