import { getLanguage } from "@/lang/index";
import { isJSON } from "@/utils/index";
import { getSessionStorage, setSessionStorage, removeSessionStorage, setLocalStorage } from "@/utils/storage";

const state = {
  language: getLanguage(),
  token: getSessionStorage("token"),
  userInfo: isJSON(getSessionStorage("userInfo")) ? JSON.parse(getSessionStorage("userInfo")) : "",
  currency: getSessionStorage("currency"),
  cancelTokenArr: [], // 取消请求token数组
  siderCollapse: false,
  paymentLogos: []
};

const mutations = {
  pushCancelToken(state, payload) {
    state.cancelTokenArr.push(payload.cancelToken);
  },

  clearCancelToken() {
    state.cancelTokenArr.forEach(cancel => {
      cancel("requestcancel");
    });
    state.cancelTokenArr = [];
  },

  SET_SIDER_COLLAPSE: (state, collapse) => {
    state.siderCollapse = collapse;
  },

  SET_LANGUAGE: (state, language) => {
    state.language = language;
    setLocalStorage("language", language);
  },

  SET_TOKEN: (state, data) => {
    state.token = data.token;
    setSessionStorage("token", data.token);
  },

  SET_APPINFO: (state, data) => {
    let userInfo = {
      userId: data.userId,
      userName: data.userName,
      userEmail: data.userEmail,
      fullName: data.userFullName,
      roleName: data.roleName,
      entName: data.userEnt,
      entDisplayName: data.entDisplayName,
      sysModulePermisson: data.permissons,
      isMasterEnterprise: data.isMasterEnterprise,
      enablePoint: data.enablePoint,
      currency: data.currency,
      mfa: data.mfa,
      enableQr: data.enableQr,
      forceBindMfa: data.forceBindMfa
    };
    state.userInfo = userInfo;
    state.currency = userInfo.currency;

    setSessionStorage("userInfo", JSON.stringify(userInfo));
  },

  RESET_APPINFO: state => {
    state.token = "";
    state.userInfo = {};
  },

  SET_PAYMENT_LOGOS: (state, data) => {
    state.paymentLogos = data;
  }
};

const actions = {
  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language);
  },

  setAppInfo({ commit }, data) {
    return new Promise(resolve => {
      commit("SET_APPINFO", data);
      resolve();
    });
  },

  setToken({ commit }, data) {
    return new Promise(resolve => {
      commit("SET_TOKEN", data);
      resolve();
    });
  },

  setPaymentLogos({ commit }, data) {
    return new Promise(resolve => {
      commit("SET_PAYMENT_LOGOS", data);
      resolve();
    });
  },

  logout({ commit }) {
    return new Promise(resolve => {
      commit("RESET_APPINFO");
      removeSessionStorage("token");
      removeSessionStorage("userInfo");
      removeSessionStorage("currency");
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
