import { getSessionStorage } from "@/utils/storage";
import store from "../store/index";
import { includes } from "lodash";
export function isJSON(str) {
  if (typeof str == "string") {
    try {
      let obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  return false;
}

//金额千分位格式化
export function moneyFormat(number) {
  let sysCurrency = getSessionStorage("currency");
  let locale = "";
  switch (sysCurrency) {
    case "JPY":
      locale = "ja-JP";
      break;
    case "CNY":
      locale = "zh-CN";
      break;
    case "MNT": //蒙古
      locale = "mn-MN";
      break;
    case "QAR": //卡塔尔
      locale = "en";
      break;
    default:
      locale = "ja-JP";
      break;
  }
  try {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: sysCurrency,
      currencyDisplay: "narrowSymbol"
    }).format(number);
  } catch {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: sysCurrency,
      currencyDisplay: "symbol"
    }).format(number);
  }
}

//数字千分位格式化
export function numFormat(number) {
  return new Intl.NumberFormat().format(number);
}

//POINT数字格式化
export function pointFormat(number) {
  return new Intl.NumberFormat().format(number) + " P";
}

export function validatePassWord(value) {
  if (value == undefined || value == null || value.length < 1) {
    return false;
  }
  //至少12个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符：
  let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.;'])[A-Za-z\d@~!@#$%^&*()_\-+=<>?:"{}|,.;']{12,}/;
  if (!reg.test(value)) {
    return false;
  } else {
    return true;
  }
}

export function validateOldPassWord(value) {
  if (value == undefined || value == null || value.length < 1) {
    return false;
  }
  //至少12个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符：
  let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.;'])[A-Za-z\d@~!@#$%^&*()_\-+=<>?:"{}|,.;']{8,}/;
  if (!reg.test(value)) {
    return false;
  } else {
    return true;
  }
}

export function validatePhone(value) {
  if (value == undefined || value == null || value.length < 1) {
    return false;
  }
  let reg = /^[0-9-]+$/;
  if (!reg.test(value)) {
    return false;
  } else {
    return true;
  }
}

//验证是否包含特殊字符
export function validateContainSpecialCharacters(value) {
  if (value == undefined || value == null || value.length < 1) {
    return false;
  }
  let reg = /^[= ]+$/;
  if (reg.test(value)) {
    return false;
  } else {
    return true;
  }
}

export function checkDifferentPassword(password1, password2) {
  if (password1 != null && password1 != undefined && password1.length > 0 && password1 != password2) {
    return false;
  }
  return true;
}
export function checkIsNull(obj) {
  if (obj == null || obj == undefined) {
    return true;
  }
  return false;
}

export function isSuperAdmin() {
  //   if (userInfo && (userInfo.entName === "__SYSTEM" || userInfo.roleName === "SuperAdmin")) return true;

  const userInfo = JSON.parse(getSessionStorage("userInfo"));
  if (userInfo && userInfo.roleName === "SuperAdmin") return true;
  return false;
}

export function isAdmin() {
  const userInfo = JSON.parse(getSessionStorage("userInfo"));
  if (userInfo && userInfo.entName === "__SYSTEM") return true;
  return false;
}

export function enablePoint() {
  const userInfo = JSON.parse(getSessionStorage("userInfo"));
  return userInfo.enablePoint;
}

export function getLoginUserInfo() {
  const userInfo = JSON.parse(getSessionStorage("userInfo"));
  return userInfo;
}

export function hasPermission(value) {
  let sysModulePermisson = store.state.app.userInfo.sysModulePermisson;
  let [modelName, permission] = value.split(".");
  let hasPermission = false;
  for (let item of sysModulePermisson) {
    if (item.modelName === modelName) {
      hasPermission = includes(item.permissonList, permission);
    }
  }
  return hasPermission;
}

//根据货币代码获取货币符号
export function getCurrencySymbol(currencyCode) {
  const currencySymbols = {
    USD: "$", // 美元
    EUR: "€", // 欧元
    JPY: "¥", // 日元
    GBP: "£", // 英镑
    AUD: "A$", // 澳大利亚元
    CAD: "C$", // 加拿大元
    CHF: "CHF", // 瑞士法郎
    CNY: "¥", // 人民币
    SEK: "kr", // 瑞典克朗
    NZD: "NZ$", // 新西兰元
    MNT: "₮", // 蒙古图格里克
    QAR: "ر.ق" // 卡塔尔里亚尔
    // ... 添加更多货币
  };

  return currencySymbols[currencyCode] || currencyCode;
}
