export default {
  roleDetail: "Role Detail",
  userRight: "Role Management",
  newRight: "Create New Role",
  roleName: "Name",
  rightAbility: "Access Permission Rights",
  aboutTrade: "About Transaction",
  aboutTradeSearch: "Search for Transaction",
  aboutTradeDataDownload: "Download Transaction Data",
  aboutPayment: "About Refund",
  aboutPaymentRefund: "Performing a Refund",
  tradeSearch: "Transaction Search",
  tradeDownload: "Download Transactions",
  commentEdit: "Remarks Column",
  receiptSearch: "Deposit Search",
  receiptDownload: "Download Deposits",
  userSearch: "User Search",
  newUser: "Create New User",
  userEdit: "Edit User",
  userDel: "Delete User",
  rightGroupSearch: "Role Search",
  newRightGroup: "Create New Role",
  rightGroupEdit: "Edit Role",
  rightGroupDel: "Delete Role",
  statistic: "Aggregate",
  statisticDownload: "Download",
  saveNewRight: "Create",
  PermissionGroup: "Role",
  Permission: "Search",
  PermissionAdd: "Create",
  PermissionDelete: "Delete",
  PermissionEdit: "Edit",
  RecordGroup: "Transaction",
  Record: "Search",
  RecordDownload: "Download",
  RefundGroup: "Refund",
  Refund: "Refund",
  SettleGroup: "Deposit",
  Settle: "Search",
  SettleDownload: "Download",
  ShopGroup: "Store",
  Shop: "Store Manage",
  StatGroup: "Aggregate",
  Stat: "Search",
  StatDownload: "Download",
  UserGroup: "User",
  User: "Search",
  UserAdd: "Create",
  UserDelete: "Delete",
  UserEdit: "Edit",
  MessageGroup: "Message",
  Message: "Search",
  MessageAdd: "Create",
  MessageDelete: "Delete",
  MessageEdit: "Edit",
  DataCompass: "Data Compass",
  PointsReport: "Points Report",
  EntPointsReport: "Enterprise Data Query"
};
