import http from "../http";

export const getUserInfo = params => {
  return http({
    method: "get",
    url: "/api/user/userinfo",
    params
  });
};
export const getSysEntList = params => {
  return http({
    method: "get",
    url: "/api/user/entlist",
    params
  });
};

export const getUserList = params => {
  return http({
    method: "get",
    url: "/api/user/userlist",
    params
  });
};

export const getSysUserList = params => {
  return http({
    method: "get",
    url: "/api/user/sysadminuserlist",
    params
  });
};

export const userDelete = data => {
  return http({
    method: "delete",
    url: "/api/user/delete",
    data
  });
};

export const shoplist = params => {
  return http({
    method: "get",
    url: "/api/user/shoplist",
    params
  });
};

export const addUser = data => {
  return http({
    method: "post",
    url: "/api/user/add",
    data
  });
};

export const addSysUser = data => {
  return http({
    method: "post",
    url: "/api/user/addsysuser",
    data
  });
};

export const modifyUser = data => {
  return http({
    method: "put",
    url: "/api/user/modify",
    data
  });
};

export const modifySysUser = data => {
  return http({
    method: "put",
    url: "/api/user/modifysysuser",
    data
  });
};

export const resetPassword = data => {
  return http({
    method: "put",
    url: "/api/user/resetpassword",
    data
  });
};
export const associateFcm = data => {
  return http({
    method: "post",
    url: "/api/user/associatefcm",
    data
  });
};

export const unassociateFcm = data => {
  return http({
    method: "post",
    url: "/api/user/unassociatefcm",
    data
  });
};

export const associateSns = data => {
  return http({
    method: "post",
    url: "/api/user/associatesns",
    data
  });
};

export const getEntMFAList = params => {
  return http({
    method: "get",
    url: "/api/user/entmfaconfiglist",
    params
  });
};

export const updateEntMFAConfig = data => {
  return http({
    method: "put",
    url: "/api/user/entmfaconfig",
    data
  });
};
