export default {
  tradeSearch: "Гүйлгээ",
  tradeDateRange: "Гүйлгээ хийсэн огноо",
  orderDateRange: "Гүйлгээ хийсэн огноо",
  tradeStroeHint: "Бүх дэлгүүр",
  tradNo: "Төлбөр/Буцаалтын дугаар",
  tradNoHint: " P000000-000000",
  outTradNoHint: "ENTESHOP0000P000000000000",
  transactionIdHint: "000000-000000000000-00000000",
  paymentOptions: "Төлбөрийн төрөл",
  tradePayKind: "Төлбөрийн төрөл",
  tradeDataByDateRange: "Гүйлгээний тоон мэдээлэл",
  tradeCount: "Нийт гүйлгээний тоо",
  tradeTotalPayAmount: "Нийт төлбөрийн дүн",
  tradeTotalRefundAmount: "Нийт буцаалтын дүн",
  tradeDeductionAmount: "Цэвэр дүн",
  tradeDetail: "Дэлгэрэнгүй",
  tradeCountPay: "тооны арилжаа",
  paymentDifferent: "Төлбөрийн ангилал",
  tradeRefundInfo: "Буцаалтын мэдээлэл",
  paymentInfo: "Төлбөрийн мэдээлэл",
  tradeInfo: "Худалдааны мэдээлэл",
  tradeRefund: "Буцаалт хийх",
  tradeRefundButtonInfo: "Эдгээр арилжааг буцаах тохиодолд дараах товчлуур дээр дарж буцаалтыг хийнэ үү.",
  tradeRefundSettingInfo: "Буцаалт хийх үнийн дүнг оруулан нягталсны дараа үйлдлийг гүйцээнэ үү",
  tradeChangeApply: "Өөрчлөх хүсэл илгээх",
  tradePaidCount: "Төлбөр хийгдсэн мөнгөн дүн",
  tradeRefundedCount: "Буцаалт хийх мөнгөн дүн",
  tradeCountSet: "Мөнгөн дүнг оруулах",
  tradeMoney: "Төлбөрийн мөнгөн дүн",
  tradeRefundMoney: "Буцаалтын мөнгө дүн",
  PleaseEnterRefundAmount: "Хэмжээ оруулна уу",
  refundDetail: "Буцаан олголтын дэлгэрэнгүй мэдээлэл",
  refundFeeDataError: "Та буцаан олголтын дүн болох 0 иенийг зааж өгөх боломжгүй.",
  point: "цэг",
  payAmount: "Нийт үйл ажиллагаа",
  combinationIdAndPaymentTotalAmount: "Бүлгийн дугаар (нийт дүн)",
  combinationId: "Бүлгийн дугаар",
  combinationPaymentTotalAmount: "Нийт дүн",
  unsupportedRefund: "Хямдралын үйл ажиллагаанд POS машины хөнгөлөлтийн функцийг ашиглана уу",
  orderAmount: "Захиалгын хэмжээ",
  totalExtTransactions: "Бэлэн мөнгө болон бусад нийт",
  totalExtCount: "Бэлэн мөнгө болон бусад боть",
  totalExtAmount: "Бэлэн мөнгө болон бусад нийт",
  transactionId: "Үйлчилгээний Id",
  totalTradeOrder: "Нийт захиалга",
  totalPspTransactions: "Нийт түрийвчний худалдаа",
  pspTradeRefundTotalCount: "Нийт түрийвчний төлбөр, буцаан олголт",
  totalAmount: "Нийт хэмжээ",
  totalPointTransactions: "Нийт цэгийн үйл ажиллагаа",
  totalExtTradeRefundCount: "Нийт төлбөр, буцаан олголтын тоо",
  containRemark: "Нэмэлт тэмдэглэлүүдийг файлруу экспортлож байна",
  containRemarkWithAlipay: "備考情報（備考欄・AliPay＋情報）をファイル出力",
  containRemarkTip: "Тайлбар мэдээлэл агуулсан файл гаргахыг хүсэж байгаа эсэхийг шалгана уу",
  containPaymentMethod: "クレジットカード決済における一括払い・分割払い等の支払方法",
  containBankType: "Alipay＋の詳細ブランド情報",
  bonusType: "бонус төрөл",
  consumptionPointsAwarded: "Хэрэглээний цэг гардууллаа",
  campaignPoints: "Хяналтын цэгүүд",
  filename: "Гүйлгээний хайлт",
  sheetname: "Гүйлгээний хайлт",
  cell1: "Гүйлгээний дугаар",
  cell2: "Гүйлгээний огноо",
  cell3: "Дэлгүүрийн нэр",
  cell4: "Терминалын дугаар",
  cell5: "Гүйлгээний төрөл",
  cell6: "Төлбөрийн төрөл",
  cell7: "Гүйлгээний дүн",
  row1: "Борлуулалт",
  row2: "Буцаан олголт"
};
