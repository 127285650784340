export default {
  tradeDataLabel: "гүйлгээний өгөгдөл",
  tradeAmountLabel: "Төлбөр дүн",
  tradeQuantityLabel: "Төлбөрийн тоо",
  refundAmountLabel: "Буцаалтын дүн",
  refundQuantityLabel: "Буцаалтын тоо",

  storeScaleTitle: "дэлгүүрийн гүйлгээний харьцааны график",
  storeScaleGraphTitle: "дэлгүүрийн хувь",
  trendGraphTitle0: "Өнөөдрийн арилжааны чиг хандлага",
  trendGraphTitle1: "Энэ долоо хоногийн арилжааны чиг хандлага",
  trendGraphTitle2: "Энэ сарын арилжааны чиг хандлага",
  trendGraphTitle3: "Улирлын арилжааны чиг хандлага",
  trendGraphTitle4: "Энэ жилийн арилжааны чиг хандлага",
  allCompanyLabel: "Бүх компаниуд",
  allStoreLabel: "Бүх дэлгүүрүүд",
  tradeDailyTitle: "өдөр тутмын гүйлгээний тайлан",
  tradeMonthlyTitle: "Гүйлгээний сарын тайлан",
  tradeSubsidiaryLabel: "охин компани",
  tradeCountByCompany: "Дэлгүүрийн статистик мэдээлэл",
  tradeCountByShop: "Дэлгүүрийн статистик",
  tradeCountNotByPaymentType: "Төлбөр брэндийн статистик",
  tradeCountByPaymentType: "Төлбөрийн брэндийн статистик",
  tradeCountTimeLabel: "хугацаа",
  tradeSearchBtn: "хайх",
  tradeDownloadBtn: "Өгөгдлийг татаж авах",
  dateColumnLabel: "огноо, цаг",
  dateMonthColumnLabel: "жил, сар",
  companyCodeColumnLabel: "компанийн код",
  companyNameColumnLabel: "Компанийн нэр",
  storeCodeColumnLabel: "дэлгүүрийн код",
  storeNameColumnLabel: "Дэлгүүрийн нэр",
  paymentTypeColumnLabel: "Төлбөрийн хэлбэр",
  paymentNumColumnLabel: "Төлбөрийн тоо",
  paymentAmountColumnLabel: "төлбөр",
  refundNumColumnLabel: "Буцаалтын тоо",
  refundAmountColumnLabel: "Буцаалтын дүн",
  deductedAmountColumnLabel: "хасагдсан дүн",
  tradeDataTitle: "Заасан хугацааны гүйлгээний өгөгдөл",
  tradeDataPaymentNum: "Төлбөрийн тоо：",
  tradeDataPaymentAmount: "төлбөр：",
  tradeDataRefundNum: "Буцаан олголтын тоо：",
  tradeDataRefundAmount: "Буцаан олголтын хэмжээ：",
  tradeDataDeductedAmount: "хасагдсан дүн：",
  todayLabel: "өнөөдөр",
  weekLabel: "энэ долоо хоногт",
  monthLabel: "энэ сар",
  lastMonthLabel: "өнгөрсөн сар",
  monthBeforeLastLabel: "хоёр сарын өмнө",
  quarterLabel: "энэ улирал",
  yearLabel: "энэ жил",
  otherLabel: "бусад",
  totalValue: "ерөнхий нийлбэр"
};
