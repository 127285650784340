export default {
  tradeSearch: "Transaction Search",
  tradeDateRange: "Transaction Period",
  orderDateRange: "Transaction Period",
  tradeStroeHint: "All Stores",
  tradNo: "Transaction Number",
  tradNoHint: " P000000-000000",
  outTradNoHint: "ENTESHOP0000P000000000000",
  transactionIdHint: "000000-000000000000-00000000",
  paymentOptions: "Payment Option(s)",
  tradePayKind: "Payment Option",
  tradeDataByDateRange: "Transaction Statistics",
  tradeCount: "Total Transactions",
  tradeTotalPayAmount: "Total Transaction Amount",
  tradeTotalRefundAmount: "Total Refund Amount",
  tradeDeductionAmount: "Net Amount",
  tradeDetail: "Details",
  tradeCountPay: "Transactions",
  paymentDifferent: "Payment Category",
  tradeRefundInfo: "Refund Information",
  paymentInfo: "Payment Information",
  tradeInfo: "Trade Information",
  tradeRefund: "Refund",
  tradeRefundButtonInfo:
    "If you would like to refund this transaction, please select the button below to process the refund.",
  tradeRefundSettingInfo: "Please confirm refund details and complete the refund process.",
  tradeChangeApply: "Confirm",
  tradePaidCount: "Amount Paid",
  tradeRefundedCount: "Amount to Be Refunded",
  tradeCountSet: "Specify an Amount",
  tradeMoney: "Payment Amount",
  tradeRefundMoney: "Refund Amount",
  PleaseEnterRefundAmount: "Please enter amount",
  refundDetail: "Refund Details",
  refundFeeDataError: "Refund amount must be greater than 0",
  point: "Point",
  cost: "Amount",
  payAmount: "Total Transactions",
  combinationIdAndPaymentTotalAmount: "GroupId(Total Amount)",
  combinationId: "Combination Payment No",
  combinationPaymentTotalAmount: "Total Amount",
  unsupportedRefund: "Please use the POS machine refund function for the refund operation",
  orderPoint: "Points awarded/cancelled",
  givePoint: "Points awarded",
  refundPoint: "Points cancelled",
  orderAmount: "Order amount",
  orderNo: "Order No",
  orderTradeState: "Txn Type",
  totalExtTransactions: "Cash and other totals",
  totalExtCount: "Cash and other total count",
  totalExtAmount: "Cash and other total amount",
  transactionId: "Transaction Id",
  totalTradeOrder: "Order statistics",
  totalPspTransactions: "Total Payment Transactions",
  pspTradeRefundTotalCount: "Total Payment Count",
  totalAmount: "Total Amount",
  totalPointTransactions: "Total Point Transactions",
  totalExtTradeRefundCount: "Total Ext Payments Count",
  containRemark: "Include Remarks Data Column in Export File",
  containRemarkWithAlipay: "備考情報（備考欄・AliPay＋情報）をファイル出力",
  containRemarkTip: "Please select this if you want to include Remarks in your exported file.",
  containPaymentMethod: "クレジットカード決済における一括払い・分割払い等の支払方法",
  containBankType: "Alipay＋の詳細ブランド情報",
  bonusType: "Bonus Type",
  consumptionPointsAwarded: "Consumption points awarded",
  campaignPoints: "Campaign Points",
  filename: "Transaction Search",
  sheetname: "Transaction Search",
  cell1: "StarpayTransactionNumber",
  cell2: "Transaction Date",
  cell3: "Store Name",
  cell4: "Terminal Sequence",
  cell5: "Transaction Type",
  cell6: "Payment Option",
  cell7: "Transaction Amount",
  cell8: "Remark",
  row1: "Payment",
  row2: "Refund",
  //注文管理
  orderFilename: "order management",
  orderSheetname: "order management",
  orderCol1: "date and time",
  orderCol2: "order number",
  orderCol3: "store",
  orderCol4: "terminal sequence",
  orderCol5: "order classification",
  orderCol6: "order amount",
  orderCol7: "payment category",
  orderCol8: "detail amount",
  orderCol9: "point payment category",
  orderCol10: "detail amount",
  orderCol11: "point presentation category",
  orderCol12: "number of point card",
  orderCol13: "point presentation/amount of cancel",
  orderCol14: "point balance",
  orderTradeState1: "payment",
  orderTradeState2: "refund"
};
