import Vue from "vue";
import store from "../store/index";
import { includes } from "lodash";

const hasPermission = value => {
  let sysModulePermisson = store.state.app.userInfo.sysModulePermisson;
  let [modelName, permission] = value.split(".");
  let hasPermission = false;
  for (let item of sysModulePermisson) {
    if (item.modelName === modelName) {
      hasPermission = includes(item.permissonList, permission);
    }
  }

  return hasPermission;
};

Vue.directive("permission", {
  inserted: (el, binding) => {
    if (!hasPermission(binding.value)) {
      el.parentNode.removeChild(el);
    }
  }
});
