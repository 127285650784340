export default {
  //数据大屏
  tradeDataLabel: "Trade Data",
  tradeAmountLabel: "Payment Amount",
  tradeQuantityLabel: "Payment Quantity",
  refundAmountLabel: "Refund Amount",
  refundQuantityLabel: "Refund Quantity",
  storeScaleTitle: "Store transaction ratio chart",
  storeScaleGraphTitle: "Store share",
  trendGraphTitle0: "Today's trading trend ",
  trendGraphTitle1: "Trading trends about this week",
  trendGraphTitle2: "Trading trends about this month",
  trendGraphTitle3: "Trading trends about this quarter",
  trendGraphTitle4: "Trading trends about this year",
  allCompanyLabel: "All Companies",
  allStoreLabel: "All",

  //取引日报&&取引月报
  tradeDailyTitle: "Daily transaction",
  tradeMonthlyTitle: "Monthly transaction",
  tradeSubsidiaryLabel: "Subsidiaries",
  tradeCountByCompany: "All store statistical data",
  tradeCountByShop: "Individual store statistical data",
  tradeCountNotByPaymentType: "All Payment brand stats",
  tradeCountByPaymentType: "Payment brand data",
  tradeCountTimeLabel: "Trading period",
  tradeSearchBtn: "Search",
  tradeDownloadBtn: "Trade data Download",
  dateColumnLabel: "Date",
  dateMonthColumnLabel: "Year and Month",
  companyCodeColumnLabel: "Company Code",
  companyNameColumnLabel: "Company Name",
  storeCodeColumnLabel: "Store Code",
  storeNameColumnLabel: "Store Name",
  paymentTypeColumnLabel: "Payment Type",
  paymentNumColumnLabel: "Payment Quantity",
  paymentAmountColumnLabel: "Payment Amount",
  refundNumColumnLabel: "Refund Quantity",
  refundAmountColumnLabel: "Refund Amount",
  deductedAmountColumnLabel: "Deducted Amount",
  tradeDataTitle: "Transaction data for the specified period",
  tradeDataPaymentNum: "Payment Quantity：",
  tradeDataPaymentAmount: "Payment Amount：",
  tradeDataRefundNum: "Refund Quantity：",
  tradeDataRefundAmount: "Refund Amount：",
  tradeDataDeductedAmount: "Deducted Amount：",
  todayLabel: "Today",
  weekLabel: "This week",
  monthLabel: "This month",
  lastMonthLabel: "Last Month",
  monthBeforeLastLabel: "The month before Last",
  quarterLabel: "This quarter",
  yearLabel: "This year",
  otherLabel: "Others",
  totalValue: "Total"
};
