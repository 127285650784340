export default {
  receive: "Орлого",
  paymentDateRange: "Орлого орсон огноо",
  accountInfo: "Дансны мэдээлэл",
  tablePaymentCount: "Орлого",
  paymentUsageTime: "Хугацаа",
  paymentTotalAmount: "Нийт дүн",
  paymentFee: "Хураамж",
  paymentRefund: "Буцаалтын дүн",
  transferAmount: "Орлогын дүн",
  acceptDateRange: "Төлбөр баталгаажсан хугацаа",
  acceptCount: "Төлбөрийн нийт дүн",
  settlementFee: "Үйлчилгээний хураамж",
  chargeFee: "Хэрэглээний татвар",
  refundCount: "Буцаалтын нийт дүн",
  refundAmount: "Буцаалтын нийт дүн",
  transferCount: "Шилжүүлгийн мөнгөн дүн",
  transferFee: "Шилжүүлгийн хураамж (татвар орсон)",
  transferFee2: "Шилжүүлгийн хураамж",
  payDate: "Төлбөр хийсэн өдөр",
  paymentShopAmount: "Хэмжээ",
  freeTax: "※ Зарим төлбөрийн брэндийг татвараас чөлөөлдөг",
  totalDeductionAmount: "Нийт хасалт хийх хэмжээ",
  givePointAmount: "Онооны хэмжээ өгөх",
  reviseAmount: "Мөнгөн дүнг шинэчлэн засварлах"
};
