export default {
  user: "Хэрэглэгч",
  userPageTitle: "Хэрэглэгчийн удирлдлага",
  merchantManagement: "Аж ахуйн нэгжийн менежмент",
  manageStores: "Дэлгүүрүүд",
  createNewUser: "Шинэ хэрэглэгч үүсгэх",
  createNewEntUser: "Шинэ хэрэглэгч үүсгэх",
  editUser: "Хэрэглэгчийн мэдээллийг засах",
  passwordRepeat: "Нууц үг/лавлагаа/",
  userDetail: "Хэрэглэгчийн дэлгэрэнгүй мэдээлэл",
  userNouseAndDel: "Хүчингүй болгох болон устгах",
  userLongTimeNouse:
    "Хэсэг хугацаанд хүчингүй болгох бол “хүчингүй болгох” ,ашиглах шаардлагагүй болсон тохиолдолд “устгана” уу.",
  superAdmin: "Системийн админ",
  administrator: "Администратор",
  manager: "Менежер",
  userRole: "Хэрэглэгч",
  meter: "Нийт",
  userDeleteConfirm: "Энэ хэрэглэгчийг устгах уу?",
  userPasswordChange: "Нууц үг солих",
  allshop: "Бүх шоп",
  noshop: "Шоп алга",
  allpermission: "Бүх эрх",
  nopermission: "Эрхгүй",
  userNameInputErr: "Оруулсан 「Хэрэглэгчийн нэр」алдаатай байна.",
  userFullNameInputErr: "Оруулсан「Нэр」алдаатай байна.",
  userStausInputErr: "Оруулсан「Статус」алдаатай байна.",
  userRightInputErr: "Оруулсан 「Эрхийн бүлгийн удирдлага」алдаатай байна.",
  manageStoreInputErr: "Оруулсан 「Хариуцсан шоп」алдаатай байна.",
  userContactInputErr: "Оруулсан 「Утас」алдаатай байна.",
  userEmailInputErr: "Оруулсан 「И-мэйл хаяг」алдаатай байна.",
  userPWDInputErr: "Оруулсан 「Нууц үг」алдаатай байна.",
  userPWDReaptInputErr: "Оруулсан 「Нууц үг (баталгаажуулах)」алдаатай байна.",
  ForceModifyPassword: "Эхний удаа нэвтрэх үед нууц үгээ солино уу",
  ForcePasswordExpired: "Нууц үгийн хүчинтэй хугацаа дуусах",
  PasswordExpirationDays: "Нууц үгийн хүчинтэй өдөр",
  FirstLoginModifyPasswordTip: "Та анх удаа нэвтрэхээсээ өмнө нууц үгээ солих ёстой.",
  PasswordExpiredTip: "Нууц үгийн хугацаа дууссан. Шинэ нууц үг тохируулна уу.",
  enableSystemMessage: "StarPay-Works дээр NetStars-с мэдэгдэл хүлээн авах",
  enableSystemEmail: "StarPay-Works дээр хүлээн авсан мэдэгдлийг имэйлээр мэдэгдэх"
};
