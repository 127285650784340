export default {
  userGuid: " ユーザーマニュアル",
  customerSupport: "カスタマーサポート",
  starpayWorkInstallation: "StarPayWorksのインストール方法",
  starpayQuestion: "お問合せ",
  starpayQuestionTitle: "ご不明点がございましたら、以下のリンク先からお問合せください。",
  starpayQeustionWindow: "公式HPお問合せ窓口",
  starpayCallSupport: "お電話によるサポート (24時間対応)",
  starpaySupportCallNo: "電話番号： 03-6555-7777",
  starpayCallInofTitle: "お電話によるサポートをご希望の方は、こちらの番号までお問合せください。",
  BusinessTime: "営業時間",
  EmergencyContactInfo: "緊急連絡先情報",
  printingPaperTitle: "ご案内",
  printingPaperContent:
    "StarPay端末のレシート用紙は幅58mm×直径40mmの感熱ロール紙であればどのメーカーでも使えます。一例として、amazonの以下の商品もご利用可能です（amazonのアカウントがあればリンク先からそのままご購入いただけます）。",
  addManualBtn: "新規条項",
  manualDialogTitle: "ユーザーマニュアル 設定",
  categoryDialogTitle: "カテゴリー設定",
  uploadFileBtn: "ファイルアップロード",
  labelManualName: "マニュアル名：",
  labelFileName: "ファイル：",
  labelCategoryName: "カテゴリー名：",
  labelcategoryOrder: "番号付け：",
  confirmDailogTitle: "表示情報",
  confirmDelManualCategory:
    "このマニュアルのカテゴリーとすべてのユーザーマニュアルを削除するかどうか確認してください。",
  confirmDelManual: "このユーザーマニュアルを削除するかどうか確認してください。",
  validCategoryNameLength: "カテゴリー名の長さは50文字まで",
  validCategoryNameRequired: "カテゴリー名を入力してください",
  validCategoryOrderRequired: "番号を入力してください",
  validCategoryOrderLength: "1から99999までの数字が必須",
  validManualNameLength: "マニュアル名の長さは100文字まで",
  validManualNameRequired: "マニュアル名を入力してください",
  validFileNameRequired: "マニュアルのファイルを選択してください",
  validFileSizeRequired: "ファイルサイズは10MBまで",
  validFileType: "非対応のファイル形式",
  noData: "データなし"
};
