export default {
  QrGroup: "QR кодын удирдлага",
  Qr: "QR код хайх",
  EditQr: "QR кодыг засах",
  DeleteQr: "QR кодыг устгана уу",
  CreateQr: "QR код үүсгэх",
  permission: {
    PermissionGroup: "Эрхийн бүлэг",
    Permission: "Эрхийн бүлэг хайх",
    PermissionAdd: "Шинэ эрхийн бүлэг үүсгэх",
    PermissionDelete: "Эрхийн бүлэг устгах",
    PermissionEdit: "Эрхийн бүлэг засварлах",
    RecordGroup: "Гүйлгээ",
    Record: "Гүйлгээ лавлах",
    RecordDownload: "Гүйлгээний хуулга татах",
    RefundGroup: "Буцаалт  хийх",
    Refund: "Буцаалт  хийх",
    SettleGroup: "Орлого",
    Settle: "Орлого хайх",
    SettleDownload: "Орлогын түүх татаж авах",
    ShopGroup: "Мерчантийн тохиргоо",
    Shop: "Мерчантын тохиргоо хийх",
    StatGroup: "Ерөнхий мэдээлэл",
    Stat: "Нэгтгэх",
    StatDownload: "Мэдээллийг татаж авах",
    UserGroup: "Хэрэглэгч",
    User: "Хэрэглэгчийг хайх",
    UserAdd: "Шинэ хэрэглэгч үүсгэх",
    UserDelete: "Хэрэглэгчийг устгах",
    UserEdit: "Хэрэглэгч хэсгийг засварлах",
    MessageGroup: "Мэдэгдэл",
    Message: "Хэрэглэгчийг хайх",
    MessageAdd: "Шинэ",
    MessageDelete: "Устгах",
    MessageEdit: "хянан тохиолдуулах",
    DataCompass: "Мэдээллийн луужин",
    PointsReport: "Онцлох мэдээ",
    EntPointsReport: "Хөтөлбөрийн мэдээллийн query",

    AdminPermissionGroup: "ахирагчийн удирдлага",
    AdminPermission: "Хэрэглэгчийг хайх",
    AdminPermissionAdd: "Шинэ",
    AdminPermissionEdit: "хянан тохиолдуулах",
    AdminPermissionDelete: "Устгах",
    EntGroup: "Аж ахуйн нэгжийн менежмент",
    EntUser: "Хэрэглэгчийг хайх",
    EntUserAdd: "Шинэ",
    EntUserEdit: "хянан тохиолдуулах",
    EntUserDelete: "Устгах",
    ManualGroup: "Хэрэглэгчийн гарын авлага",
    Manual: "Хэрэглэгчийн гарын авлага",
    ManualType: "ангилалыг хайх",
    ManualTypeAdd: "ангилалыг нэмэх",
    ManualTypeEdit: "ангилалыг засварлах",
    ManualTypeDelete: "ангилалыг устгах",
    ManualRecordAdd: "гарын авлагыг нэмэх",
    ManualRecordEdit: "гарын авлагыг засварлах",
    ManualRecordDelete: "гарын авлагыг устгах"
  }
};
