export default {
  userManagement: "User Management",
  paymentBankAccountSetting: "Set up deposit account information",
  storeManagement: "Store Management",
  enterpriseManagement: "Set up company information",
  informationChangeApply: "Apply for change of information",
  applyerInfo: "Applicant's Information",
  agentInfo: "Representative's information",
  businessType: "Industry information",
  proprietorName: "Sole Proprietor's Name",
  proprietorNameKana: "Sole proprietor's Name (Kana format)",
  proprietoChangeApply: "Apply for change "
};
