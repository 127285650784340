<template>
  <div class="zwsj">
    <img :src="nodataimg" :width="imgW" :height="imgH" />
    <div style="color: #999;font-size: 12px;margin-top: 5px">{{ this.$t("queryNull") }}</div>
  </div>
</template>

<script>
import nodataimg from "@/assets/nodata.png";
export default {
  name: "nodata",
  data() {
    return {
      nodataimg
    };
  },
  props: {
    text: {
      require: true
    },
    imgW: {
      default: "275px"
    },
    imgH: {
      default: "195px"
    }
  }
};
</script>

<style scoped>
.zwsj {
  padding: 80px 0;
  text-align: center;
}
</style>
