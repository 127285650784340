export default {
  operation: "дэлгэрэнгүй",
  dateTime: "Хугацаа",
  date: "Хугацаа",
  terminal: "Төхөөрөмжийн дугаар",
  terminalHint: "0001",
  store: "Дэлгүүр",
  storeName: "Дэлгүүрийн нэр",
  storeCode: "Дэлгүүрийн код",
  cashCount: "Нийт дүн",
  printCount: "Хэвлэлтийн тоо",
  storAbbreviation: "Дэлгүүрийн товчилсон нэр",
  enterpriseAbbreviation: "Байгууллагын товчилсон нэр",
  enterpriseCode: "Компанийн код",
  enterpriseName: "Компанийн",
  enterprise: "Компанийн",
  payDateTime: "Төлбөрийн өдөр цаг",
  payKind: "Төлбөрийн төрөл",
  payKindHint: "Бүх Төлбөрийн төрөл",
  paymentNo: "Төлбөрийн дугаар",
  outTradeNo: "Иш усны дугаар",
  otherWalletNumber: "Хэтэвчний төлбөрийн дугаар",
  deviceIdentificationNumber: "Төхөөрөмжийн танилцуулгын дугаар",
  deviceProcessingNumber: "Төхөөрөмжийн боловсруулалтын дугаар",
  tradeKind: "Гүйлгээний төрөл",
  tradKindHint: "Бүгд",
  searchReset: "нөхцлийг шинэчлэх",
  search: "Хайх",
  recordDownload: "Мэдээллийг татаж авах",
  passage: "",
  payment: "Төлбөр",
  paymentKind: "Төлбөрийн төрөл",
  kind: "Гүйлгээний төрөл",
  all: "Бүгд",
  refund: "Буцаалт",
  refundDate: "Буцаалт хийсэн өдөр цаг",
  refundNo: "Буцаалтын дугаар",
  cashAll: "Нийт дүн",
  licenseNo: "Лицензийн дугаар",
  terminalCount: "Төхөөрөмжийн тоо",
  terminalNo: "Төхөөрөмжийн дугаар",
  unknownDeviceModel: "",
  appVer: "Апликэйшн",
  device: "Төхөөрөмжийн төрөл",
  lastLoginTime: "Сүүлд нэвтэрсэн цаг",
  status: "Төлөв",
  login: "Нэвтрэх",
  logout: "Гарах",
  userId: "Хэрэглэгчийн ID",
  userName: "Хэрэглэгчийн нэр",
  userIdInputHint: "Хэрэглэгчийн ID-г оруулна уу",
  password: "Нууц үг",
  passwordInputHint: "Нууц үгээ оруулна уу",
  forgotPassword: "Нууц үгээ мартсан тохиолдолд ийшээ хандана уу",
  name: "Нэр",
  authority: "Эрхийн бүлэг",
  userAuthority: "Эрхийн бүлэг",
  address: "Мэйл хаяг",
  delete: "Устгах",
  disable: "Хүчингүй болгох",
  trade: "Гүйлгээ",
  orderManage: "Захиалга удирдах",
  paymentManage: "Төлбөрийн зохицуулалт",
  tradePoint: "Оноо шилжилт менежмент",
  dashboard: "Самбар",
  receipt: "Орлого",
  setting: "Сонголт тохируулах",
  supportCenter: "Тусламж",
  detail: "Дэлгэрэнгүй",
  bankCode: "Банкны код",
  bankName: "Банкны нэр",
  bankBranchName: "Салбарын нэр",
  bankBranchCode: "Салбарын код",
  bankNo: "Дансны дугаар",
  bankNoType: "Дансны эзэмшигчийн нэр",
  bankKind: "Дансны төрөл",
  hankakukana: "Катакана үсгээр /хагас/",
  paymentDetail: "Орлогын дэлгэрэнгүй",
  paymentShopDetail: "Дэлгүүрийн хэрэглээний дүн",
  payDate: "Төлбөр хийсэн өдөр",
  paymentDetailMore: "Төлбөрийн дэлгэрэнгүйг эксел файлаар татаж авах боломжтой",
  paymentDetailMoreDownload: "Төлбөрийн дэлгэрэнгүйг татаж авах ",
  dataDownload: "Мэдээллийг татаж авах",
  brand: "Төлбөрийн төрөл",
  notification: "Мэдэгдэл",
  noNotification: "Сонордуулга алга",
  importNotification: "Чухал сонордуулга",
  otherNotification: "Мэдээлэл",
  brandTypeRatio: "Брэнд тус бүрийн хувь хэмжээ",
  ratio: "Хувь хэмжээ",
  currentDay: "Өнөөдөр",
  currentWeek: "Энэ долоо хоног",
  currentMonth: "Энэ сар",
  today: "Өнөөдөр",
  yestoday: "Өчигдөр",
  lastWeek: "Өнгөрсөн долоо хоног",
  lastMonth: "Өнгөрсөн сар",
  quarter: "Улирлаар",
  year: "Жилээр",
  comparedTheSameDayLastMonth: "Өнгөрсөн сартай харьцуулсан",
  comparedTheSameWeekLastMonth: "Өнгөрсөн сартай харьцуулсан",
  comparedLastMonth: "Өнгөрсөн сартай харьцуулсан",
  comparedTheSameMonthLastMonth: "Өмнөх сарыг харьцуулах",
  comparedTheSameQuarterLastYear: "Өнгөрсөн жилтэй харьцуулбал",
  comparedLastYear: "Өнгөрсөн жилтэй харьцуулбал",
  startDate: "Эхэлсэн огноо",
  endDate: "Дуусах огноо",
  moneyUnit: "Төгрөг",
  money: "Мөнгөн дүн",
  count: "Тоо",
  countUnit: "Ширхэг",
  editPassword: "Нууц үгээ солих",
  newPassword: "Шинэ нууц үг",
  oldPassword: "Одоогийн нууц үг",
  rePassword: "Нууц үгээ баталгаажуулах",
  pwdDiffMsg: "Оруулсан нууц үгнүүд тохирохгүй байна",
  savePassword: "Нууц үгээ солих",
  currentPassword: "Шинэ нууц үг",
  save: "Хадгалах",
  effective: "Хүчинтэй",
  uneffective: "Хүчингүй",
  email: "И-мэйл",
  phoneNum: "Утас",
  cancel: "Цуцлах",
  submit: "Баталгаажуулах",
  success: "амжилт",
  fail: "алдаа",
  confirm: "Баталгаажуулах",
  on: "Асаах",
  off: "унтраах",
  more: "Дэлгэрэнгүйг үзэх",
  toggleMenu: "цэсийг сэлгэх",
  onlineHelp: "Онлайн Тусламж",
  remark: "Тайлбар",
  editRemark: "Тэмдэглэл засварлах",
  tips: {
    dataSearchDelay: "*Төлбөр хийгдсэнээс хойш хэсэг хугацааны дараа энэ дэлгэц дээр харагдахыг анхаарна уу"
  },
  twoStepAuth: "2 шатлалт баталгаажуулалт",
  twoStepEmail: "Баталгаажуулах код хүлээн авах имэйл хаяг",
  confirmTwoStepEmail: "Имэйл хаягаа дахин баталгаажуулна уу",
  twoStepSetSuccess: "Хоёр түвшиний баталгаажуулалт амжилттай дууслаа.",
  configuredTwoStepEmail: "Одоо ашиглаж байгаа имэйл хаяг",
  resetMFA: "Имэйл хаяг өөрчлөлтийг баталгаажуулна уу",
  rules: {
    requireEmail: "Та имэйл хаягаа оруулна уу.",
    errorEmail: "Та зөвхөн баталгаажуулах боломжтой имэйл хаяг оруулна уу.",
    errorConfirmEmail: "2 талбар дээр оруулсан имэйл хаяг таарахгүй байна."
  },
  dataCompassRouter: "Өгөгдлийн компас",
  dataScreenRouter: "Өгөгдлийн том дэлгэц",
  tradeDailyRouter: "өдрийн шинжилгээ",
  tradeMonthlyRouter: "сарын шинжилгээ",
  fontSize: "Үсгийн хэмжээ",
  large: "L",
  medium: "M",
  small: "S",
  operation: "дэлгэрэнгүй",
  btnEditRuleText: "Засварлах"
};
