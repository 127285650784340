<template>
  <el-main class="app-main">
    <router-view />
  </el-main>
</template>
<script>
export default {
  name: "Main"
};
</script>

<style>
.app-main {
  padding: 0 40px !important;
  height: calc(100vh - 60px);
  overflow-y: scroll;
}
</style>
