export default {
  message: {
    notification: "通知",
    notificationManagement: "通知管理",
    notificationList: "通知一覧",
    title: "タイトル",
    type: "通知種別",
    all: "全部",
    importantNotfication: "重要通知",
    otherNotification: "その他通知",
    search: "検索する",
    create: "新規作成",
    creator: "作成者",
    releaseTime: "掲載時間",
    operation: "操作",
    edit: "変更",
    details: "詳細",
    content: "内容",
    receiver: "通知受取先",
    userName: "ユーザー名",
    name: "名前",
    enterprise: "所属企業",
    plzChooseTheType: "通知種別を選択してください",
    plzEnterTheTitle: "通知タイトルを入力してください",
    plzEnterTheContent: "通知内容を入力してください",
    plzChooseTheReceiver: "通知先アカウントを選択してください",
    noData: "データなし",
    deleteMessageTips: "通知を削除しますか？（削除した場合、企業加盟店は本通知情報を受信できません）",
    allUsers: "すべてのユーザー",
    specifiedUser: "ユーザー指定",
    titleTips: "100文字以内を推奨",
    contentTips: "通知内容は法令に準拠する必要があります。",
    //230308 新增 可编辑 文言
    editType: "通知タイプ",
    editable: "編集可",
    notEditable: "編集不可",
    duedit: "公開後再編集機能",
    //240105 新增 发送邮件文言
    sendEmail: "メール通知",
    delete: "削除",
    recipientList: "受取先一覧"
  }
};
