export default {
  QRManage: "QR Code Management",
  createQRCode: "Create QR Code",
  QRCode: "QRCode",
  amount: "Fixed price",
  notFixedAmount: "非固定",
  isFixedAmount: "固定",
  color: "Color",
  delQRCodeTips: "QRコードを削除しますか？",
  size: "Size",
  downloadQRCode: "決済用QRコードをダウンロード",
  storeIdInputErr: "「店舗名」入力に誤りがあります。",
  amountInputErr: "「価格」入力に誤りがあります。",
  uploadSizeErr: "アップロードされたファイルに誤りがあります。",
  logoTips: `Recommended image size: 100px width, 100px height
  file format: jpeg/jpg/png (file size: up to 1MB).`,
  logoColorTips: `For the success rate of scanning, it is recommended to use darker colors. 
  (Light colors such as white and gray cannot be scanned effectively.)`
};
