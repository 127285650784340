import Vue from "vue";
import VueI18n from "vue-i18n";
import ja from "./ja";
// import zh from "./zh-CN";
import en from "./en";
import mn from "./mn";

import elementJaLocale from "element-ui/lib/locale/lang/ja";
// import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementMnLocale from "element-ui/lib/locale/lang/mn";
import { getLocalStorage } from "@/utils/storage";

Vue.use(VueI18n);

const messages = {
  ja: {
    ...ja,
    ...elementJaLocale
  },

  // zh: {
  //   ...elementZhLocale,
  //   ...zh
  // },

  en: {
    ...elementEnLocale,
    ...en
  },
  mn: {
    ...elementMnLocale,
    ...mn
  }
};

const i18n = new VueI18n({
  locale: getLanguage(),
  messages
});

export function getLanguage() {
  const chooseLanguage = getLocalStorage("language");
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  const language = (window.navigator["language"] || window.navigator["browserLanguage"]).toLowerCase();

  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return "ja";
}

export default i18n;
