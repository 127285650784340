export default {
  QrGroup: "QRコード管理",
  Qr: "QRコード検索",
  EditQr: "QRコード編集",
  DeleteQr: "QRコード削除",
  CreateQr: "QRコード作成",
  permission: {
    PermissionGroup: "ロール管理",
    Permission: "検索",
    PermissionAdd: "新規",
    PermissionDelete: "削除",
    PermissionEdit: "編集",
    RecordGroup: "取引管理",
    Record: "検索",
    RecordDownload: "ダウンロード",
    RefundGroup: "返金",
    Refund: "返金",
    SettleGroup: "入金検索",
    Settle: "検索",
    SettleDownload: "ダウンロード",
    ShopGroup: "店舗管理",
    Shop: "検索",
    StatGroup: "集計管理",
    Stat: "集計",
    StatDownload: "集計ダウンロード",
    UserGroup: "ユーザー管理",
    User: "検索",
    UserAdd: "新規",
    UserDelete: "削除",
    UserEdit: "編集",
    MessageGroup: "通知管理",
    Message: "検索",
    MessageAdd: "新規",
    MessageDelete: "削除",
    MessageEdit: "編集",
    DataCompass: "データ分析",
    PointsReport: "ポイントレポート",
    EntPointsReport: "企業データ検索",

    AdminPermissionGroup: "管理者管理",
    AdminPermission: "検索",
    AdminPermissionAdd: "新規",
    AdminPermissionEdit: "編集",
    AdminPermissionDelete: "削除",
    EntGroup: "加盟店管理",
    EntUser: "検索",
    EntUserAdd: "新規",
    EntUserEdit: "編集",
    EntUserDelete: "削除",
    ManualGroup: "ユーザーマニュアル管理",
    Manual: "ユーザーマニュアル管理",
    ManualType: "カテゴリーの検索",
    ManualTypeAdd: "カテゴリーの新規",
    ManualTypeEdit: "カテゴリーの編集",
    ManualTypeDelete: "カテゴリーの削除",
    ManualRecordAdd: "マニュアルの追加",
    ManualRecordEdit: "マニュアルの編集",
    ManualRecordDelete: "マニュアルの削除"
  }
};
