<template>
  <div class="app-wrapper">
    <sidebar class="sidebar-container" />
    <el-container class="main-container" :class="{ 'sider-is-collapse': isCollapse }">
      <main-header />
      <main-content v-if="!$store.state.app.userInfo.forceBindMfa" />
    </el-container>
    <div :class="!isCollapse ? 'collapse expand' : 'collapse close'" @click="setCollapse"></div>
  </div>
</template>
<script>
import Sidebar from "./components/Sidebar";
import MainHeader from "./components/Header";
import MainContent from "./components/Main";

export default {
  name: "Layout",
  components: {
    Sidebar,
    MainHeader,
    MainContent
  },
  methods: {
    setCollapse() {
      this.$store.commit("app/SET_SIDER_COLLAPSE", !this.isCollapse);
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.app.siderCollapse;
    }
  }
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  height: 100%;

  .main-container {
    flex-direction: column;
    margin-left: 260px;
    transition: 0.3s all ease;

    &.sider-is-collapse {
      margin-left: 112px;
    }
  }
}
</style>
