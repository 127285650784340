export default {
  httpFail: "Мэдээллийг боловсруулахад алдаа гарлаа",
  noChanged: "Өөрчлөх утга олдсонгүй, хүсэлт цуцлагдлаа",
  paramError: "Параметрийн алдаа",
  queryNull: "Мэдээлэл алга",
  purviewLack: "Бүрэн эрхгүй тул үйлдэл хийх боломжгүй",
  illegalOperation: "Хууль бус үйлдэл",
  warning: "Анхаар",
  error: "Системийн алдаа",
  ssoError: "SSO алдаа",
  refundError: "Алдаа, бидэнтэй холбоо барина уу.",
  orderRefunded: "Гүйлгээг буцаан олголоо. Буцаах боломжгүй.",
  refundExceed: "Буцаан олгох хэмжээнээс хэтэрсэн байна.",
  refundFeeError: "Буцаан олголтын хэмжээг оруулахад алдаа гарав.",
  refundFail:
    "Алдаа гарвал бидэнтэй холбоо барина уу. Гүйлгээ хийсэн огноо, цагийг тэмдэглээд бидэнтэй холбоо барина уу.",
  refundDelay:
    "返金は操作完了しました。クレジットカードの返金は若干のタイムラグの場合がありますので、しばらくお待ちください。",
  refundDelayTips: "クレジットカードの返金は若干のタイムラグの場合がありますので、ご了承ください。",
  incorrectUserInfo: "Хэрэглэгчийн ID/нууц үг алдаатай",
  userDisadbled: "Данс блоклогдлоо",
  userNotExist: "Бүртгэлгүй данс",
  userExist: "Бүртгэлтэй данс",
  newNotEqualsOld: "Одооны нууц үгээс ялгаатай байх шаардлагатай",
  invalidPasswordFormat: "12 оронгоос дээш, Алфабет үсэг, тоо, тэмдэг үсэг орсон байх шаардлагатай.",
  invalidOldPasswordFormat: "8 оронгоос дээш, Алфабет үсэг, тоо, тэмдэг үсэг орсон байх шаардлагатай.",
  noUserName: "Хэрэглэгчийн дугаараа оруулна уу",
  noPassword: "Нууц үгээ оруулна уу",
  userNameExist: "Хэрэглэгчийн нэр аль хэдийн байна",
  userEmailExist: "Имэйлийг бүртгэсэн байна",
  passwordCanNotContainUserName: "Нууц үг нь хэрэглэгчийн нэрийг агуулж болохгүй",
  entNotExist: "Компани байхгүй",
  tradeSearchPeriodLimit: "3 сарын дотор Гүйлгээ лавлах хугацааны цар хүрээг тодорхойлно уу",
  statisticSearchPeriodLimit: "Нэг сарын хугацаанд нэгтгэх хугацааг зааж өгнө үү",
  differentPasswordInput: "Ижил нууц үгээ оруулна уу",
  roleNameIsNull: "Зөвшөөрлийн нэрийг оруулна уу",
  outTradeNoIsNull: "Ажил гүйлгээний дугаараа оруулна уу",
  roleUsed: "Үүргийг ашиглаж байгаа тул устгахыг хориглоно",
  roleExist: "Тэмдэгт аль хэдийн байна",
  roleNotExist: "Үүрэг байхгүй",
  nonePermissionSelected: "Сонгон шалгаруулалтад алдаа гарсан байна",
  invalidPasswordHistory: "Буруу нууц үг: Сүүлийн 5 нууц үгийн аль нэгтэй тэнцүү байх ёсгүй.",
  networkError: "Сервертэй холбогдох боломжгүй байна, сүлжээгээ шалгана уу эсвэл дараа дахин оролдоно уу",
  uploadFail: "Fail татаж авах",
  uploadImageSizeLimit: "Зургийн хэмжээ хязгаар нь 2М",
  errorRemarkFormat: "100 хүртэлх үсэг.",
  remarkIsNull: "Та бүхэн хэлсэн үгэндээ оруулаарай",
  referenceServiceUnavailable: "Харамсалтай нь, онцгой байдлаас болж гуравдагч талын үйлчилгээ авах боломжгүй байна."
};
