<template>
  <el-popover
    class="ratingPopover"
    :disabled="popoverDisabled"
    :placement="placement"
    :trigger="disabled ? 'manual' : 'click'"
    v-model="visible"
    ><div class="ratingContent">
      <!-- 弹窗内容 -->
      <p style="font-weight: bold; width: 80%;margin-bottom:12px;">{{ rateTitle }}</p>

      <template v-for="(item, index) in localRateData">
        <template v-if="item.type === 'star'">
          <p>{{ item.title }}</p>
          <el-rate v-model="item.rating" @change="submitRating(item)" :disabled="item.disabled"></el-rate
        ></template>
      </template>
      <!-- <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click="close">取消</el-button>
      <el-button type="primary" size="mini" @click="submitRating">确定</el-button>
    </div> -->
    </div>

    <!-- 触发按钮的插槽 -->
    <template #reference>
      <div v-loading="loading" style="display: inline-block;"><slot></slot></div>
    </template>
    <el-button class="closeBtn" icon="el-icon-close" type="text" @click="close"></el-button>
  </el-popover>
</template>

<script>
import Axios from "axios";
export default {
  name: "RatingPopover",
  props: {
    defaultVisible: {
      type: Boolean,
      default: false
    },
    rateTitle: {
      type: String,
      default: "より良いサービスを提供するために、貴重な意見を残してください。"
    },
    rateData: {
      type: Array,
      default: function() {
        // return [{ title: "この機能はどう思いますか？", type: "star" }];
        return [];
      },
      validator: function(value) {
        return value.every(function(item) {
          return item.hasOwnProperty("title") && item.hasOwnProperty("type");
        });
      }
    },
    placement: {
      type: String,
      default: "bottom" // 可以设置为 'top', 'right', 'bottom', 'left'
    },
    showFrequency: {
      type: String,
      default: "session",
      validator: function(value) {
        return ["once", "everytime", "session", "never"].includes(value);
      }
    }
  },
  data() {
    return {
      loading: true,
      visible: false,
      rating: 0,
      disabled: false,
      popoverDisabled: false,
      localRateData: [],
      activeKey: (() => window.location.href.split("/#/")[1].split("?")[0])()
    };
  },
  methods: {
    close() {
      this.popoverDisabled = true;
    },
    checkFrequency() {
      console.log("in check");
      console.log(this.$store.state.app.userInfo.userName);
      const frequencyActions = {
        once: () =>
          Boolean(
            localStorage.getItem(
              `${this.$store.state.app.userInfo.userName}|${this.activeKey}|${JSON.stringify(this.rateData)}`
            )
          ),
        session: () =>
          Boolean(
            sessionStorage.getItem(
              `${this.$store.state.app.userInfo.userName}|${this.activeKey}|${JSON.stringify(this.rateData)}`
            )
          ),
        everytime: () => false,
        never: () => true
      };

      const action = frequencyActions[this.showFrequency];
      console.log("action", action());
      this.popoverDisabled = action();
    },
    checkBackendHealth() {
      return Axios.get(`${this.$baseUrl}/edge/health`)
        .then(res => {
          console.log(res);
          console.log("edge 后端接触成功，展示评价插件");
          if (res.status === 200) {
            this.checkFrequency();
            this.loading = false;
          }
        })
        .catch(err => {
          console.log("edge 后端接触失败，不展示评价插件");
          console.log("err", err);
          this.popoverDisabled = true;
          this.loading = false;
        });
    },
    checkCompletion() {
      console.log("in checkCompletion");

      for (let item in this.localRateData) {
        console.log(this.localRateData[item]);
        // this.popoverDisabled = true;
        if (this.localRateData[item].disabled === false) {
          return false;
        }
      }

      if (this.showFrequency === "once") {
        localStorage.setItem(
          `${this.$store.state.app.userInfo.userName}|${this.activeKey}|${JSON.stringify(this.rateData)}`,
          "true"
        );
      } else if (this.showFrequency === "session") {
        sessionStorage.setItem(
          `${this.$store.state.app.userInfo.userName}|${this.activeKey}|${JSON.stringify(this.rateData)}`,
          "true"
        );
      }
      this.popoverDisabled = true;
    },
    submitRating(item) {
      console.log("rate", item);
      Axios.post(`${this.$baseUrl}/edge/review`, item)
        .then(res => {
          console.log(res);
          if (res.status === 201) {
            item.disabled = true;
            item.visible = true;
            setTimeout(() => {
              this.checkCompletion();
            }, 2000);
          }
        })
        .catch(err => {
          console.log("err", err);
          item.disabled = true;
          setTimeout(() => {
            this.checkCompletion();
          }, 2000);
        });
      // this.$emit("rate", { feature: this.feature, rating: this.rating });
      // this.close();
    }
  },
  created() {
    this.localRateData = this.rateData.map(item => ({
      ...item,
      disabled: item.disabled || false,
      rating: item.rating || 0,
      visible: item.visible || false
    }));
  },
  mounted() {
    this.checkBackendHealth().then(res => {
      this.visible = this.defaultVisible;
    });
  }
};
</script>
<style lang="scss">
.el-button + .ratingPopover {
  margin-left: 10px;
}
.closeBtn {
  position: absolute;
  right: 16px;
  top: 0;
  font-size: 20px;
  color: #333 !important;
}
</style>
