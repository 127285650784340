export default {
  userGuid: "Хэрэглэгчийн заавар",
  customerSupport: "Холбоо барих",
  starpayWorkInstallation: "StarPayWorks-ыг татах",
  starpayQuestion: "Холбоо барих",
  starpayQuestionTitle: "Тодруулах зүйлээ доорх линкээр орж лавлана уу.",
  starpayQeustionWindow: "Албан ёсны вэб хуудасны лавлах",
  starpayCallSupport: "Холбоо барих /24 цаг/",
  starpaySupportCallNo: "Утас: 03-6555-7777",
  starpayCallInofTitle: "Утсаар лавлах бол энэ дугаарт холбогдоно уу.",
  BusinessTime: "Ажиллах цаг",
  EmergencyContactInfo: "Яаралтай үед холбоо барих"
};
