<template>
  <span v-if="showFeedback">
    <el-badge value="!" class="item">
      <el-button
        @click="showDialog = true"
        icon="el-icon-edit-outline"
        style="border: 1px solid #e2e2e2;color: #606266;background-color:#fff;font-size: 20px;padding:12px 0px; width: 44px;border-radius: 0;margin:8px 0"
      ></el-button>
    </el-badge>

    <el-dialog
      :visible.sync="showDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      class="app-dialog feedbackDialog"
      custom-class="dialog-box"
      top="0"
      width="50%"
    >
      <div slot="title">
        <div class="dialog-close" @click="showDialog = false">
          <svg-icon icon-class="dialog_close"></svg-icon>
        </div>
        <div class="dialog-title" style="margin-top: 26px;">
          <span>フィードバックを作成する</span>
          <el-button
            @click="showDialogDetails = true"
            type="text"
            style="font-size: 20px;padding:0;height: 22px; float:right;margin-right:20px"
            >フィードバック記録</el-button
          >
        </div>
      </div>
      <el-form ref="feedbackFormRef" :model="form" :rules="rules" v-loading="formLoading">
        <el-form-item label="分類" class="is-required" prop="title">
          <el-select style="width:100%" v-model="form.title">
            <el-option v-for="(item, index) in cateList" :key="index" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" class="is-required" prop="content">
          <el-input
            v-model="form.content"
            type="textarea"
            placeholder="入力してください"
            rows="4"
            maxlength="1000"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="添付ファイル（写真添付可能）">
          <div style="clear:both;width:100%">
            <div
        v-for="(item, index) in images"
        :key="index"
        class="image-container"
        style="position: relative; display: inline-block; margin-right: 10px; margin-bottom: 10px;"
      >
        <el-image
          :src="item"
          fit="cover"
          style="width:100px;height:100px;border:1px solid #d9d9d9;border-radius: 6px;"
        ></el-image>
        <i
          class="el-icon-delete"
          style="font-size: 20px;position: absolute;top: 1px;left: 1px;cursor: pointer;background-color: rgba(255, 255, 255, 0.6);width: 100px;height: 100px;text-align: center;line-height: 100px;display:none;"
          @click="removeImage(index)"
        ></i>
      </div>
            <el-upload
              class="avatar-uploader"
              action=""
              :http-request="uploadFile"
              :show-file-list="false"
              :before-upload="beforeUpload"
              v-loading="uploadLoading"
            >
              <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <div class="dialog-footer" style="text-align: right;">
          <el-button class="cancel" @click="showDialog = false">
            {{ $t("cancel") }}
          </el-button>
          <el-button class="confirm" type="primary" @click="submit">
            {{ $t("submitFeedback") }}
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="showDialogDetails"
      @open="getFeedbackData"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      class="app-dialog feedbackDetails"
      custom-class="dialog-box"
      top="0"
      width="45%"
    >
      <div slot="title">
        <div class="dialog-close" @click="showDialogDetails = false">
          <svg-icon icon-class="dialog_close"></svg-icon>
        </div>
        <div class="dialog-title" style="margin-top: 26px;">
          <span>フィードバックレコード</span>
        </div>
      </div>
      <div class="detailsList" style="line-height: normal;max-height: 80vh;overflow: auto;" v-loading="feedbackDetailsLoading">
        <el-radio-group v-model="feedbackDetailsRadio" @change="getFeedbackData">
          <el-radio label="ent">すべて</el-radio>
          <el-radio label="user">私のフィードバック</el-radio>
        </el-radio-group>
        <el-empty v-if="feedBackList.length === 0" description=" " style="line-height:28px">
          <p>今のところフィードバック記録は何もありません。</p>
          <p>貴重な提案を歓迎します。今すぐ書きましょう。</p>
        </el-empty>
        <template v-else>
          <el-collapse v-model="feedbackDetailsCollapse" accordion>
            <div v-for="(item, index) in feedBackList" class="feedbackDetailsItem">
              <el-collapse-item style="padding-right:20px" :name="item.timestamp">
                <template slot="title">
                  <div>
                    <h4 style="display: block;">{{ item.title }}</h4>
                    <!-- <h5 style="display: block;">{{ item.content }}</h5> -->
                  </div>
                </template>
                <pre>{{ item.content }}</pre>
                <template v-if="feedbackDetailsCollapse == item.timestamp">
                <span v-for="(item, index) in item.fileList" :key="index"> 
                  <img
                  :src="$baseUrl + '/edge/download?fileName=' + item"
                  fit="cover"
                  v-once
                  style="width:100px;height:100px;margin-top:5px;margin-bottom:5px;margin-right:10px;border:1px solid #d9d9d9;border-radius: 6px;"
                ></img>
                </span>
                </template>
                

                <!-- <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div> -->
                <!-- <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div> -->
              </el-collapse-item>
              <el-descriptions :column="3" size="mini">
                <el-descriptions-item label="提出者 ">{{ item.userName }}</el-descriptions-item>
                <el-descriptions-item label="提出時間 ">{{
                  new Date(item.timestamp).toLocaleString()
                }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </el-collapse>
        </template>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import Axios from "axios";
export default {
  name: "Feedback",
  props: {
    defaultVisible: {
      type: Boolean,
      default: false
    },
    rateTitle: {
      type: String,
      default: "より良いサービスを提供するために、貴重な意見を残してください。"
    },
    rateData: {
      type: Array,
      default: function() {
        // return [{ title: "この機能はどう思いますか？", type: "star" }];
        return [];
      },
      validator: function(value) {
        return value.every(function(item) {
          return item.hasOwnProperty("title") && item.hasOwnProperty("type");
        });
      }
    },
    placement: {
      type: String,
      default: "bottom" // 可以设置为 'top', 'right', 'bottom', 'left'
    },
    showFrequency: {
      type: String,
      default: "session",
      validator: function(value) {
        return ["once", "everytime", "session", "never"].includes(value);
      }
    }
  },
  data() {
    return {
      feedbackDetailsCollapse:'',
      feedbackDetailsRadio: "ent",
      showDialogDetails: false,
      cateList: [
        "ダッシュボード関係",
        "取引関係",
        "ポイント関係",
        "入金関係",
        "集計関係",
        "通知関係",
        "店舗関係",
        "端末関係",
        "アカウント関係",
        "マニュアル関係",
        "その他"
      ],
      feedBackList: [],
      form: {
        title: "ダッシュボード関係",
        content: "",
        fileList: []
      },
      rules: {
        title: [{ required: true, trigger: "blur" }],
        content: [{ required: true, message: "内容を入力してください", trigger: "blur" }]
      },

      images: [],
      showFeedback: false,
      showDialog: false,
      loading: false,
      formLoading: false,
      feedbackDetailsLoading: false,
      uploadLoading: false,
      visible: false,
      rating: 0,
      disabled: false,
      popoverDisabled: false,
      localRateData: [],
      activeKey: (() => window.location.href.split("/#/")[1].split("?")[0])()
    };
  },
  methods: {
    getFeedbackData() {
      let item = {
        type: "sendBack",
        platform: "StarPay-Works",
        entCode: this.$store.state.app.userInfo.entName
      };

      if (this.feedbackDetailsRadio === "user") {
        item["exactUserName"] = this.$store.state.app.userInfo.userName;
      }

      this.feedbackDetailsLoading = true;
      // this.feedBackList = [];

      Axios.get(`${this.$baseUrl}/edge/reviews`, { params: item })
        .then(res => {
          console.log(res);
          if (res.status === 200) {
            this.feedBackList = res.data.result;
            this.feedbackDetailsLoading = false;
            // this.form = {
            //   title: "ダッシュボード関係",
            //   content: "",
            //   fileList: []
            // };
            // this.formLoading = false;
            // this.showDialog = false;
            // this.$message.success("成功");
          }
        })
        .catch(err => {
          console.log(err);
          // this.formLoading = false;
          this.feedbackDetailsLoading = false;
          this.$message.error("失敗");
        });
    },
    removeImage(index) {
      this.images.splice(index, 1); // 从数组中移除图片
      this.form.fileList.splice(index,1)
    },
    submit() {
      this.$refs.feedbackFormRef.validate(valid => {
        if (valid) {
          let item = {
            title: this.form.title,
            content: this.form.content,
            fileList: this.form.fileList,
            type: "sendBack",
            platform: "StarPay-Works",
            entCode: this.$store.state.app.userInfo.entName,
            entName: this.$store.state.app.userInfo.entDisplayName,
            userName: this.$store.state.app.userInfo.userName,
            oem:this.$settings.oem
          };
          this.formLoading = true;
          console.log(item);
          Axios.post(`${this.$baseUrl}/edge/review`, item)
            .then(res => {
              console.log(res);
              if (res.status === 201) {
                this.form = {
                  title: "ダッシュボード関係",
                  content: "",
                  fileList: []
                };
                this.images = [];
                this.formLoading = false;
                this.showDialog = false;
                this.$message.success("成功");
              }
            })
            .catch(err => {
              console.log(err);
              this.formLoading = false;
              this.$message.error("失敗");
            });
        } else {
          console.log("表单验证失败");
          return false;
        }
      });
    },
    beforeUpload(file) {
      console.log(file.type);
      const allowedFormats = [
        "image/png",
        "image/jpeg"
        // 'application/x-apple-diskimage',
        // 'text/csv',
        // 'application/pdf',
        // 'application/msword',
        // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // 'application/vnd.ms-excel',
        // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // 'application/vnd.ms-powerpoint',
        // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ];
      const isJPG = allowedFormats.includes(file.type);
      const isLt10M = file.size / 1024 / 1024 < 10;

      console.log(isJPG, isLt10M);

      if (!isJPG) {
        this.$message.error("サポートされていないファイル形式です。");
        return false;
      }

      if (!isLt10M) {
        this.$message.error("ファイル形式が異なるか、ファイルサイズが制限を超えています。");
        return false;
      }
      return isJPG && isLt10M;
    },
    close() {
      this.popoverDisabled = true;
    },
    uploadFile(e) {
      this.uploadLoading = true;
      const formData = new FormData();
      formData.append("file", e.file);

      Axios.post(`${this.$baseUrl}/edge/streamUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          console.log("File uploaded successfully", response);
          this.uploadLoading = false;
          if (response.data.statusCode === 200) {
            this.form.fileList.push(response.data.data.fileName);
            this.images.push(URL.createObjectURL(e.file));
            this.$message.success("成功");
          } else {
            this.$message.error("失敗");
          }
        })
        .catch(error => {
          this.uploadLoading = false;
          console.error("Error uploading file", error);
        });
    },

    checkFrequency() {
      console.log("in check");
      console.log(this.$store.state.app.userInfo.userName);
      const frequencyActions = {
        once: () =>
          Boolean(
            localStorage.getItem(
              `${this.$store.state.app.userInfo.userName}|${this.activeKey}|${JSON.stringify(this.rateData)}`
            )
          ),
        session: () =>
          Boolean(
            sessionStorage.getItem(
              `${this.$store.state.app.userInfo.userName}|${this.activeKey}|${JSON.stringify(this.rateData)}`
            )
          ),
        everytime: () => false,
        never: () => true
      };

      const action = frequencyActions[this.showFrequency];
      console.log("action", action());
      this.popoverDisabled = action();
    },
    checkBackendHealth() {
      return Axios.get(`${this.$baseUrl}/edge/health`)
        .then(res => {
          console.log(res);
          console.log("edge 后端接触成功，展示评价插件");
          if (res.status === 200) {
            this.showFeedback = true;
            // this.checkFrequency();
            // this.loading = false;
          }
        })
        .catch(err => {
          console.log("edge 后端接触失败，不展示评价插件");
          console.log("err", err);
          this.popoverDisabled = true;
          this.loading = false;
        });
    },
    checkCompletion() {
      console.log("in checkCompletion");

      for (let item in this.localRateData) {
        console.log(this.localRateData[item]);
        // this.popoverDisabled = true;
        if (this.localRateData[item].disabled === false) {
          return false;
        }
      }

      if (this.showFrequency === "once") {
        localStorage.setItem(
          `${this.$store.state.app.userInfo.userName}|${this.activeKey}|${JSON.stringify(this.rateData)}`,
          "true"
        );
      } else if (this.showFrequency === "session") {
        sessionStorage.setItem(
          `${this.$store.state.app.userInfo.userName}|${this.activeKey}|${JSON.stringify(this.rateData)}`,
          "true"
        );
      }
      this.popoverDisabled = true;
    },
    submitRating(item) {
      console.log("rate", item);
      Axios.post(`${this.$baseUrl}/edge/review`, item)
        .then(res => {
          console.log(res);
          if (res.status === 201) {
            item.disabled = true;
            item.visible = true;
            setTimeout(() => {
              this.checkCompletion();
            }, 2000);
          }
        })
        .catch(err => {
          console.log("err", err);
          item.disabled = true;
          setTimeout(() => {
            this.checkCompletion();
          }, 2000);
        });
      // this.$emit("rate", { feature: this.feature, rating: this.rating });
      // this.close();
    }
  },
  created() {
    this.localRateData = this.rateData.map(item => ({
      ...item,
      disabled: item.disabled || false,
      rating: item.rating || 0,
      visible: item.visible || false
    }));
  },
  mounted() {
    this.checkBackendHealth().then(res => {
      this.visible = this.defaultVisible;
    });
  }
};
</script>
<style lang="scss">
.image-container:hover{
  .el-icon-delete{
    display: block !important;
  }
}
.el-badge.item {
  .el-badge__content {
    min-width: 6px;
  }
  .el-badge__content.is-fixed {
    top: 10px;
  }
}
.el-collapse {
  border: none;
}
.feedbackDetailsItem {
  margin: 10px 0;
  background-color: #ebeef5;
  padding-left: 20px;
  padding-bottom: 6px;

  .el-collapse-item__header {
    background-color: #ebeef5;
  }
  .el-collapse-item__content {
    padding-bottom: 0px;
    // margin-bottom: 10px;
  }
  .el-collapse-item__wrap {
    background-color: #ebeef5;
  }
  .el-descriptions__body {
    background-color: #ebeef5;
  }
}
.feedbackDetails {
  .dialog-box {
    min-width: 600px;
  }
}
.feedbackDialog {
  .dialog-box {
    min-width: 600px;
  }
  .el-form-item__label {
    text-align: left;
  }
  .avatar-uploader {
    height: 102px;
    display: inline-block;
    vertical-align: top;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: var(--baseColor);
  }
  .avatar-uploader-icon {
    font-size: 16px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}

.el-button + .ratingPopover {
  margin-left: 10px;
}
.closeBtn {
  position: absolute;
  right: 16px;
  top: 0;
  font-size: 20px;
  color: #333 !important;
}

.dialog-title {
  height: 22px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  font-size: 22px;
}

.dialog-close {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.dialog-close .svg-icon {
  width: 28px;
  height: 28px;
  vertical-align: -8px;
}

.app-dialog .el-dialog {
  border-radius: 16px;
}

.app-dialog .el-dialog__header {
  //   padding: 18px;
  padding-bottom: 2px;
}

.app-dialog .el-dialog__body {
  overflow: auto;
}

.app-dialog .el-dialog__footer {
  height: 80px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 32px;
  line-height: 36px;
}
</style>
