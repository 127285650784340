<template>
  <el-dropdown @command="handleSetLanguage">
    <span class="languageSelect">
      <!-- <svg-icon icon-class="language"></svg-icon> -->
      <img src="./../assets/newStyle/icon_i18n.png" />
    </span>
    <el-dropdown-menu slot="dropdown" align="center">
      <el-dropdown-item lang="ja" :disabled="language === 'ja'" command="ja">
        日本語
      </el-dropdown-item>
      <el-dropdown-item lang="en" :disabled="language === 'en'" command="en">
        English
      </el-dropdown-item>
      <el-dropdown-item lang="mn" :disabled="language === 'mn'" command="mn">
        Монгол
      </el-dropdown-item>
      <!--
      <el-dropdown-item :disabled="language === 'zh'" command="zh">
        中文
      </el-dropdown-item>
      -->
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "LanguageSelect",
  computed: {
    language() {
      return this.$store.state.app.language;
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("app/setLanguage", lang);
      window.location.reload();
    }
  }
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  top: 40px !important;
}
</style>
