export default {
  roleDetail: "権限の詳細",
  userRight: "ロール管理",
  newRight: "新規ロール作成",
  roleName: "ロール名",
  rightAbility: "権限",
  aboutTrade: "取引に関して",
  aboutTradeSearch: "取引の検索",
  aboutTradeDataDownload: "取引データのダウンロード",
  aboutPayment: "返金に関して",
  aboutPaymentRefund: "返金の実行",
  tradeSearch: "取引検索",
  tradeDownload: "取引ダウンロード",
  commentEdit: "備考欄編集",
  receiptSearch: "入金検索",
  receiptDownload: "入金ダウンロード",
  userSearch: "ユーザー検索",
  newUser: "新規ユーザー",
  userEdit: "ユーザー編集",
  userDel: "ユーザー削除",
  rightGroupSearch: "ロール検索",
  newRightGroup: "新規ロール",
  rightGroupEdit: "ロール編集",
  rightGroupDel: "ロール削除",
  statistic: "集計",
  statisticDownload: "集計ダウンロード",
  saveNewRight: "作成する",
  PermissionGroup: "ロール管理",
  Permission: "検索",
  PermissionAdd: "新規",
  PermissionDelete: "削除",
  PermissionEdit: "編集",
  RecordGroup: "取引管理",
  Record: "検索",
  RecordDownload: "ダウンロード",
  RefundGroup: "返金",
  Refund: "返金",
  SettleGroup: "入金検索",
  Settle: "検索",
  SettleDownload: "ダウンロード",
  ShopGroup: "店舗管理",
  Shop: "検索",
  StatGroup: "集計管理",
  Stat: "集計",
  StatDownload: "集計ダウンロード",
  UserGroup: "ユーザー管理",
  User: "検索",
  UserAdd: "新規",
  UserDelete: "削除",
  UserEdit: "編集",
  MessageGroup: "通知管理",
  Message: "検索",
  MessageAdd: "新規",
  MessageDelete: "削除",
  MessageEdit: "編集",
  DataCompass: "データ分析",
  PointsReport: "ポイントレポート",
  EntPointsReport: "企業データ検索"
};
