export default {
  tradeSearch: "取引管理",
  tradeDateRange: "取引期間",
  orderDateRange: "注文期間",
  tradeStroeHint: "すべての店舗",
  tradNo: "支払/返金番号",
  tradNoHint: "P000000-000000",
  outTradNoHint: "ENTESHOP0000P000000000000",
  transactionIdHint: "000000-000000000000-00000000",
  paymentOptions: "決済種別",
  tradePayKind: "決済種別",
  tradeDataByDateRange: "指定した期間の取引データ",
  tradeDeductionAmount: "差引金額",
  totalAmount: "総金額",
  //point
  payTotalCount: "支払件数",
  refundTotalCount: "返金件数",
  payTotalAmount: "支払金額",
  refundTotalAount: "返金金額",
  tradeCount: "総件数",
  tradeSalesCount: "売上件数",
  tradeSalesAmount: "売上金額",
  tradeRefundCount: "返金件数",
  tradeRefundAmount: "総返金額",
  pspTradeRefundTotalCount: "ウォレット支払と返金総件数",
  tradePointUseCount: "ポイント利用と取消件数",
  tradePointUseAmount: "ポイント利用総数",
  tradePointGiveCount: "ポイント進呈と取消件数",
  tradePointGiveAmount: "ポイント進呈総数",
  totalExtTradeRefundCount: "支払と返金総件数",
  tradeTotalPayAmount: "総利用額",
  tradeTotalRefundAmount: "総返金額",
  totalTradeOrder: "注文総計",
  totalPspTransactions: "ウォレット取引総計",
  totalPointTransactions: "ポイント取引総計",
  totalExtTransactions: "現金及びその他総計",
  totalExtCount: "現金及びその他件数",
  totalExtAmount: "現金及びその他総額",
  orderNo: "注文番号",
  transactionId: "ポイント取引ID",
  orderTradeState: "注文区分",
  orderPoint: "ポイント進呈/取消",
  givePoint: "ポイント進呈",
  refundPoint: "進呈キャンセル",
  eachAmount: "詳細金額",
  mchNo: "Mch番号",
  pointAmount: "ポイント数",
  pointCost: "积分代金",
  cost: "金額",
  orderAmount: "注文金額",
  pointCard: "ポイントカード番号",
  pointType: "积分交易类型",
  pointUse: "积分利用",
  pointGive: "积分進呈",
  payAmount: "決済金額",
  pointUseSuccess: "利用",
  pointUseRefund: "利用取消",
  pointGiveSuccess: "進呈",
  pointGiveRefund: "進呈取消",
  bonusType: "進呈区分",
  consumptionPointsAwarded: "消費ポイント付与",
  campaignPoints: "キャンペーンポイント",
  //point end
  tradeDetail: "詳細",
  tradeCountPay: "件の取引",
  tradeRefundInfo: "返金情報",
  paymentInfo: "支払情報",
  tradeInfo: "取引情報",
  tradeRefund: "返金する",
  tradeRefundButtonInfo: "こちらの取引を返金する場合は以下のボタンから返金処理を行ってください。",
  tradeRefundSettingInfo: "返金金額を設定、確認の上返金処理を完了してください",
  tradeChangeApply: "返金を実行する",
  tradePaidCount: "返金可能金額",
  tradeRefundedCount: "返金する金額",
  tradeCountSet: "金額を指定する",
  tradeMoney: "支払金額",
  tradeRefundMoney: "返金金額",
  PleaseEnterRefundAmount: "金額を入力してください",
  refundDetail: "返金の詳細",
  refundFeeDataError: "返金額は０円を指定することはできません。",
  point: "ポイント",
  combinationIdAndPaymentTotalAmount: "组番号(総金額)",
  combinationId: "组番号",
  combinationPaymentTotalAmount: "総金額",
  unsupportedRefund: "返金操作にはPOSマシン返金機能をご利用ください",
  containRemark: "StarPayアプリ上で入力した備考情報",
  containRemarkWithAlipay: "備考情報（備考欄・AliPay＋情報）をファイル出力",
  containRemarkTip: "出力データに以下の情報を含める場合はチェックをしてください。",
  containPaymentMethod: "クレジットカード決済における一括払い・分割払い等の支払方法",
  containBankType: "Alipay＋の詳細ブランド情報",
  filename: "取引検索",
  sheetname: "取引検索",
  cell1: "取引番号",
  cell2: "利用時間",
  cell3: "利用店舗",
  cell4: "端末",
  cell5: "区分",
  cell6: "種別",
  cell7: "利用額",
  cell8: "備考",
  row1: "売上",
  row2: "返金",
  //注文管理
  orderFilename: "注文管理",
  orderSheetname: "注文管理",
  orderCol1: "日時",
  orderCol2: "注文番号",
  orderCol3: "利用店舗",
  orderCol4: "端末番号",
  orderCol5: "注文区分",
  orderCol6: "注文金額",
  orderCol7: "決済種別",
  orderCol8: "詳細金額",
  orderCol9: "ポイント決済種別",
  orderCol10: "詳細金額",
  orderCol11: "ポイント進呈種別",
  orderCol12: "ポイントカード番号",
  orderCol13: "ポイント進呈/取消数量",
  orderCol14: "ポイント残高",
  orderTradeState1: "支払",
  orderTradeState2: "返金"
};
