export default {
  statisticTimeRange: "Aggregate Period",
  statisticByDateRange: "Statistics",
  statisticTotalCount: "Total Count",
  statisticTotalAmount: "Total Amount",
  statisticPayCount: "Total Payment Count",
  statisticPayAmount: "Total Payment Amount",
  statisticRefundCount: "Total Refund Count",
  statisticRefundAmount: "Total Refund Amount",
  listByDate: "Aggregate By Date",
  listByCode: "Aggregate By Store Code",
  listColTotalCount: "Total Count",
  listColTotalAmount: "Total Amount",
  listColPayCount: "Payment Count",
  listColPayAmount: "Payment Amount",
  listColRefundCount: "Refund Count",
  listColRefundAmount: "Refund Amount",
  file1: {
    name: "DailyStatistics",
    head1: "Period",
    head2: "Company",
    bodyTitle: "Daily Statistics",
    cell1: "Date",
    cell2: "Total No of Transactions",
    cell3: "Total Transaction Amount",
    cell4: "No of Payments",
    cell5: "Transaction Amount",
    cell6: "No of Refunds",
    cell7: "Refund Amount",
    cell8: "Net Amount",
    cell9: "Transaction Amount",
    cell0: "Total"
  },
  file2: {
    name: "StoreStatistics",
    head1: "Period",
    head2: "Company",
    bodyTitle: "Store Statistics",
    cell1: "Store Code",
    cell2: "Store Name",
    cell3: "Total No of Transactions",
    cell4: "Total Transaction Amount",
    cell5: "No of Payments",
    cell6: "Transaction Amount",
    cell7: "No of Refunds",
    cell8: "Refund Amount",
    cell9: "Net Amount",
    cella: "Transaction Amount",
    cell0: "Total"
  },
  file3: {
    name: "EntStatistics",
    cell1: "Company Code",
    cell2: "Company Name"
  }
};
