export default {
  httpFail: "Processing is unsuccessful",
  noChanged: "Processing is cancelled because there are no changes",
  paramError: "Parameter error",
  queryNull: "No data",
  purviewLack: "Insufficient permissions to operate",
  illegalOperation: "Unauthorised operation",
  warning: "Note",
  error: "System error",
  ssoError: "SSO Error",
  refundError: "Error, please contact us.",
  orderRefunded: "The transaction has been refunded. Non-refundable.",
  refundExceed: "Exceeds the refundable amount.",
  refundFeeError: "There is an error in entering the refund amount.",
  refundFail: "Refund Failed! Please record the date and time of the transaction and contact US.",
  refundDelay:
    "返金は操作完了しました。クレジットカードの返金は若干のタイムラグの場合がありますので、しばらくお待ちください。",
  refundDelayTips: "クレジットカードの返金は若干のタイムラグの場合がありますので、ご了承ください。",
  incorrectUserInfo: "Invalid user ID / password",
  userDisadbled: "Account is locked",
  userNotExist: "Account does not exist",
  userExist: "Account already exists",
  newNotEqualsOld: "The password must be different from the current password",
  invalidPasswordFormat:
    "The password should have at least 12 characters including all of these conditions: one capital letter, one small letter, one number, one symbol.",
  invalidOldPasswordFormat:
    "The password should have at least 8 characters including all of these conditions: one capital letter, one small letter, one number, one symbol.",
  noUserName: "Please enter your user ID",
  noPassword: "Please enter your password",
  userNameExist: "Username already exists",
  userEmailExist: "The email has been registered",
  passwordCanNotContainUserName: "Password cannot contain username",
  entNotExist: "Company does not exist",
  tradeSearchPeriodLimit: "Please specify the range of the transaction search period within 3 months.",
  statisticSearchPeriodLimit: "Please specify the range of the aggregation period within one month.",
  differentPasswordInput: "Please input same password.",
  roleNameIsNull: "Please input role name.",
  outTradeNoIsNull: "Please enter the transaction number.",
  roleUsed: "Role is in use, it is forbidden to delete.",
  roleExist: "The role already exists",
  roleNotExist: "Role does not exist",
  nonePermissionSelected: "Please select one permission at least",
  invalidPasswordHistory: "Invalid password: must not be equal to any of last 5 passwords.",
  networkError: "Unable to connect to the server, please check your network or try again later",
  uploadFail: "Failed to upload",
  uploadImageSizeLimit: "The image size limit is 2M",
  errorRemarkFormat: "Within 100 words.",
  remarkIsNull: "Please input remark.",
  referenceServiceUnavailable: "Unfortunately, third-party services are unavailable due to exception."
};
