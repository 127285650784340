export default {
  QRManage: "QR кодын удирдлага",
  createQRCode: "QR код үүсгэх",
  QRCode: "Төлбөрийн QRкод",
  amount: "Тогтмол үнэ",
  notFixedAmount: "非固定",
  isFixedAmount: "固定",
  color: "өнгө",
  logoTips: `画像の推奨サイズ：100px、横100px
  拡張子：jpeg/jpg/png（容量：1MBまで）`,
  logoColorTips: `スキャンの成功率に緊がる為、濃い目の色がお勧めします。
  (白、グレーなど薄めの色がスキャンできません。)`,
  delQRCodeTips: "QRコードを削除しますか？",
  size: "Хэмжээ",
  downloadQRCode: "決済用QRコードをダウンロード",
  storeIdInputErr: "「店舗名」入力に誤りがあります。",
  amountInputErr: "「価格」入力に誤りがあります。",
  uploadSizeErr: "アップロードされたファイルに誤りがあります。",
  logoTips: `Үзэгдлийн зургийн зөвлөгөө: 100 пиксель x 100 пиксель, 
  файлын формат: jpeg/jpg/png (файлын хэмжээ: хамгийн их 1MB)`,
  logoColorTips: `Сканы амжилтанд том хэмжээний өнгөтэй болох нь зөвхөн хангалттай бөгөөд, 
    цайвар өнгө (цагаан, хар, гэрэл зэрэг) өнгө нь скан хийх боломжгүй.`
};
