export default {
  user: "User",
  userPageTitle: "User Management",
  merchantManagement: "Merchant Management",
  manageStores: "Stores",
  createNewUser: "Create New User",
  createNewEntUser: "Create New User",
  editUser: "Edit User Info",
  passwordRepeat: "Confirm New Password",
  userDetail: "User Details",
  userNouseAndDel: "Delete",
  userLongTimeNouse: 'Please click "Delete" if you want to remove this User permanently.',
  superAdmin: "System Administrator",
  administrator: "Top-level Administrator",
  manager: "Manager",
  userRole: "User",
  meter: "Record",
  userDeleteConfirm: "Are you sure that you want to delete this user?",
  userPasswordChange: "Change Password",
  allshop: "All Stores",
  noshop: "No Store",
  allpermission: "All Permissions",
  nopermission: "No Permission",
  userNameInputErr: '"Username" Input Error',
  userFullNameInputErr: '"Name" Input Error',
  userStausInputErr: '"Status" Input Error',
  userRightInputErr: '"User Role" is required',
  manageStoreInputErr: '"Stores" is required',
  userContactInputErr: '"Phone" Input Error ',
  userEmailInputErr: '"Email" Input Error',
  userPWDInputErr: '"Password" Input Error',
  userPWDReaptInputErr: "Password(Confirmation) Input Error",
  ForceModifyPassword: "Change password when logging in for the first time",
  ForcePasswordExpired: "Force password expiration",
  PasswordExpirationDays: "Password expiration days",
  FirstLoginModifyPasswordTip: "You must change your password before you log in for the first time.",
  PasswordExpiredTip: "The password has expired. Please set a new password.",
  enableSystemMessage: "Receive notifications from NetStars on StarPay-Works",
  enableSystemEmail: "Notify by email about notifications received on StarPay-Works"
};
