<template>
  <el-dialog
    :width="dialogWidth"
    v-if="showDialog"
    :visible.sync="showDialog"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
    class="app-dialog"
    custom-class="dialog-box"
    top="0"
  >
    <div slot="title">
      <div class="dialog-close" @click="dialogClose">
        <svg-icon icon-class="dialog_close"></svg-icon>
      </div>
      <div class="dialog-title">{{ dialogTitle }}</div>
    </div>

    <slot></slot>

    <span slot="footer" class="dialog-footer" v-if="showFooter">
      <slot name="footer">
        <el-button class="cancel" @click="dialogClose">
          {{ cancelText }}
        </el-button>
        <el-button class="confirm" type="primary" @click="dialogToCommit">
          {{ commitText }}
        </el-button>
      </slot>
    </span>
  </el-dialog>
</template>

<script>
import i18n from "@/lang";

export default {
  name: "ModalDialog",

  props: {
    // 显示隐藏
    showDialog: {
      type: Boolean,
      default: false
    },

    showFooter: {
      type: Boolean,
      default: true
    },

    // 默认宽度
    dialogWidth: {
      type: String,
      default: "50%"
    },

    // 标题
    dialogTitle: {
      type: String,
      default: ""
    },

    // 取消文案
    cancelText: {
      type: String,
      default: i18n.t("cancel")
    },

    // 确认文案
    commitText: {
      type: String,
      default: i18n.t("confirm")
    }
  },
  methods: {
    // 确认回调
    dialogToCommit() {
      this.$emit("commitDialog", "commit");
    },

    dialogClose() {
      console.log("close");
      this.$emit("update:showDialog", false);
      this.$emit("closeDialog", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1920px) {
  .el-dialog {
    width: 30% !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1920px) {
  .el-dialog {
    width: 40% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .el-dialog {
    width: 60% !important;
  }
}

.dialog-title {
  height: 22px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  font-size: 22px;
}

.dialog-close {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.dialog-close .svg-icon {
  width: 28px;
  height: 28px;
  vertical-align: -8px;
}

.app-dialog .el-dialog {
  border-radius: 16px;
}

.app-dialog .el-dialog__header {
  padding: 18px;
  padding-bottom: 2px;
}

.app-dialog .el-dialog__body {
  overflow: auto;
}

.app-dialog .el-dialog__footer {
  height: 80px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 32px;
  line-height: 36px;
}
</style>
