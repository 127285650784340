export default {
  shopPageTitle: "店舗の管理",
  shopOnline: "オンラインショップ",
  shopOffline: "オフライン店舗",
  shopDeviceTitle: "登録されている端末",
  downloadShopDevices: "端末一覧をダウンロード",
  downloadShopList: "店舗一覧をダウンロード",
  shopInfo: "店舗情報",
  shopReceiptName: "レシート名",
  shopTradeTypes: "開通済み決済種別",
  shopCreateDateTime: "店舗登録時間",
  qrCode: "認証QRコード",
  showDisableDevice: "無効な端末を表示",
  onlineServiceId: "オンラインサービスID",
  offlineServiceId: "オフラインサービスID",
  storeService: "サービス",
  onlineService: "オンライン取引",
  offlineService: "オフライン取引",
  storeServiceId: "サービスID",
  signKey: "SignKey",
  use: "利用",
  useRate: "利用比率",
  give: "進呈",
  giveRate: "進呈比率",
  cash: "现金",
  wallet: "钱包",
  creditCard: "信用卡",
  pointsLimit: "ポイント進呈限度額 [他社入金（現金を含む）の進呈限度額のみ]",
  singleAlertValue: "一回決済の警告値",
  dailyAlertValue: "単日警告値",
  singleLimit: "単日警告値",
  dailyLimit: "単日限度額",
  //积分规则
  pointTypeLabel: "ポイントタイプ",
  ruleIdLabel: "規則ID",
  originalRuleIdLabel: "元ルールID",
  activityTypeLabel: "イベントタイプ",
  ruleNameLabel: "イベント名",
  startTimeLabel: "イベント開始時間",
  endTimeLabel: "イベント終了時間",
  activityDayLabel: "イベント日",
  ratioLabel: "進呈率",
  baseRatioLabel: "一般進呈比率",
  stateLabel: "状態",
  updateTimeLabel: "更新時間",
  btnAddRuleText: "新規イベント",
  btnEditRuleText: "編集",
  btnDetailRuleText: "詳細",
  pointsRuleText: "ポイント規則",
  pointsRuleHistory: "ポイント規則履歴",
  currentValidPointsRules: "現在有効なポイント規則",
  downloadPointsRulesList: "ダウンロード",
  shopManagePointsRulesTitle: "店舗管理-ポイント規則",
  addPointsRuleTitle: "新規イベント画面",
  editPointsRuleTitle: "執行中修正画面詳細",
  activityValidityPeriod: "有効期限",
  baseActivityValidityPeriod: "一般イベント有効期限",
  fromText: "から",
  toText: "まで",
  activityMultipleSetLabel: "倍数設定",
  activityMultipleLabel: "倍数",
  activityRemarkLabel: "イベント申請備考",
  activityDayWeekText: "毎週",
  activityDayWeekText1: "月曜日",
  activityDayWeekText2: "火曜日",
  activityDayWeekText3: "水曜日",
  activityDayWeekText4: "木曜日",
  activityDayWeekText5: "金曜日",
  activityDayWeekText6: "土曜日",
  activityDayWeekText7: "日曜日",
  activityDayDateText: "每月",
  activityDayDateLastText: "月底",
  activityDayEverydayText: "每日",
  activityTypeText1: "一般",
  activityTypeText2: "特殊",
  activityDayTypeText0: "每日",
  activityDayTypeText1: "每月",
  activityDayTypeText2: "毎週",
  activityStatusValid: "有効",
  activityStatusInvalid: "無効",
  detailPointsRuleTitle: "イベント詳細インターフェース",
  createSourceLabel: "イベント開始システム",
  activicyCreateTimeLabel: "新規増加時間",
  addPointsRuleTips:
    "【新規特殊イベント】は【一般ポイントルール】の設定に基づき（有効期限/進呈比率）　上記条件に満足する上で倍数を設定する",
  ruleNameRequiredValid: "必ずイベント名を入力して下さい。",
  ruleNameLengthValid: "50文字まで入力できます。",
  activityValidityPeriodRequiredValid: "必ずイベント有効期間を設定して下さい。",
  activityDayRequiredValid: "必ずイベント日を設定して下さい。",
  activityRemarkLengthValid: "100文字まで入力できます。",
  returnBtn: "戻る",
  onlineShopAbbr: "オンライン略称",
  offlineShopAbbr: "オフライン略称"
};
