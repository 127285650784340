<template>
  <el-drawer
    class="app-sidelayer"
    :visible.sync="drawer"
    :show-close="false"
    :append-to-body="false"
    :direction="direction"
    :close-on-press-escape="false"
    :wrapperClosable="wrapperClosable"
    :size="$attrs.size"
    @close="close"
  >
    <template slot="title">
      <div class="close-btn" @click="sideLayerToClose">
        <i class="el-icon-close"></i>
      </div>
    </template>

    <div class="app-sidelayer-content" ref="content">
      <slot></slot>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "SideLayer",
  props: {
    // 显示侧边弹出
    showSideLayer: {
      type: Boolean,
      default: false
    },
    wrapperClosable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      direction: "rtl"
    };
  },

  computed: {
    drawer: {
      get() {
        return this.showSideLayer;
      },

      set() {
        this.$emit("update:showSideLayer", false);
      }
    }
  },

  methods: {
    // 关闭回调
    sideLayerToClose() {
      this.$emit("closeSideLayer", "close");
      this.$emit("update:showSideLayer", false);
    },
    close() {
      this.$emit("close");
    }
  },

  updated() {
    this.$nextTick(() => {
      if (this.$refs["content"]) this.$refs["content"].scrollTop = 0;
    });
  }
};
</script>

<style lang="scss">
@media only screen and (min-width: 1920px) {
  .el-drawer {
    width: 35% !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1920px) {
  .el-drawer {
    width: 55% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .el-drawer {
    width: 80% !important;
  }
}

/* 改写element-ui 样式 */
.app-sidelayer .el-drawer {
  min-width: 394px !important;
  padding: 48px 32px;
  border: 0 !important;
  outline: none;

  .el-drawer__header {
    padding: 0;
    margin-bottom: 30px;
  }

  .close-btn {
    cursor: pointer;
    &.svg-icon {
      width: 32px;
      height: 32px;
      vertical-align: -8px;
    }
  }

  // .app-sidelayer-content {
  //   margin-top: 32px;
  // }

  .el-drawer__body {
    position: relative;
  }
}
</style>
