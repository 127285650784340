export default {
  shopPageTitle: "Store Management",
  shopOnline: "Online Store",
  shopOffline: "Offline Store",
  shopDeviceTitle: "Registered Terminals",
  downloadShopDevices: "Download Device Data",
  downloadShopList: "Download Store Data",
  shopInfo: "Store Infomation",
  shopReceiptName: "Receipt Name",
  shopTradeTypes: "Payment Options",
  shopCreateDateTime: "Created Date",
  qrCode: "License QR Code",
  showDisableDevice: "All Status",
  onlineServiceId: "Online Service Id",
  offlineServiceId: "Offline Service Id",
  storeService: "Service",
  onlineService: "Online Trade Service",
  offlineService: "Offline Trade Service",
  storeServiceId: "Service Id",
  signKey: "SignKey",
  use: "Use",
  useRate: "Use Rate",
  give: "Give",
  giveRate: "Give Rate",
  cash: "Cash",
  wallet: "Wallet",
  creditCard: "Credit Card",
  pointsLimit: "Point award limit [Maximum amount of other companies' deposits (including cash) only]",
  singleAlertValue: "Single alert value",
  dailyAlertValue: "Daily alert value",
  singleLimit: "Single limit",
  dailyLimit: "Daily limit",
  //积分规则
  pointTypeLabel: "Point type",
  ruleIdLabel: "Regulation ID",
  originalRuleIdLabel: "Past regulation ID",
  activityTypeLabel: "Event type",
  ruleNameLabel: "Event name",
  startTimeLabel: "Start time",
  endTimeLabel: "End time",
  activityDayLabel: "Event day",
  ratioLabel: "Rate",
  baseRatioLabel: "General rate",
  stateLabel: "State",
  updateTimeLabel: "Update time",
  btnAddRuleText: "New event",
  btnEditRuleText: "Edit",
  btnDetailRuleText: "Details",
  pointsRuleText: "Point regulation",
  pointsRuleHistory: "Record of point regulation",
  currentValidPointsRules: "The current effective point regulation",
  downloadPointsRulesList: "Download",
  shopManagePointsRulesTitle: "Store Management-point regulation",
  addPointsRuleTitle: "Event add page",
  editPointsRuleTitle: "Event edit page",
  activityValidityPeriod: "Validity period",
  baseActivityValidityPeriod: "General expiration date",
  fromText: "From",
  toText: "to",
  activityMultipleSetLabel: "Event multiple setting",
  activityMultipleLabel: "Event multiple",
  activityRemarkLabel: "Event comments",
  activityDayWeekText: "Weekly",
  activityDayWeekText1: "Mon.",
  activityDayWeekText2: "Tue.",
  activityDayWeekText3: "Wed.",
  activityDayWeekText4: "Thu.",
  activityDayWeekText5: "Fri.",
  activityDayWeekText6: "Sat.",
  activityDayWeekText7: "Sun.",
  activityDayDateText: "Monthly",
  activityDayDateLastText: "Lastly",
  activityDayEverydayText: "Daily",
  activityTypeText1: "Common",
  activityTypeText2: "Special",
  activityDayTypeText0: "Daily",
  activityDayTypeText1: "Monthly",
  activityDayTypeText2: "Weekly",
  activityStatusValid: "Valid",
  activityStatusInvalid: "Invalid",
  detailPointsRuleTitle: "Event details interface",
  createSourceLabel: "Event initiation system",
  activicyCreateTimeLabel: "Creation time",
  addPointsRuleTips: "The new event follows the valid period & rate of the generic event to setting the multiple.",
  ruleNameRequiredValid: "Please enter the event name.",
  ruleNameLengthValid: "50 characters can be entered.",
  activityValidityPeriodRequiredValid: "Be sure to set the validity period of the event.",
  activityDayRequiredValid: "Please set the event date.",
  activityRemarkLengthValid: "100 characters can be entered.",
  returnBtn: "Return",
  onlineShopAbbr: "Online abbreviation",
  offlineShopAbbr: "Offline abbreviation"
};
