import Cookies from "js-cookie";
export function getCookies(TokenKey) {
  return Cookies.getItem(TokenKey);
}

export function setCookies(TokenKey, token) {
  return Cookies.setItem(TokenKey, token);
}

export function removeCookies(TokenKey) {
  return Cookies.removeItem(TokenKey);
}
export function getSessionStorage(TokenKey) {
  return sessionStorage.getItem(TokenKey);
}

export function setSessionStorage(TokenKey, token) {
  return sessionStorage.setItem(TokenKey, token);
}

export function removeSessionStorage(TokenKey) {
  return sessionStorage.removeItem(TokenKey);
}

export function getLocalStorage(TokenKey) {
  return localStorage.getItem(TokenKey);
}

export function setLocalStorage(TokenKey, token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeLocalStorage(TokenKey) {
  return localStorage.removeItem(TokenKey);
}
