export default {
  shopPageTitle: "Мерчантын тохиргоо",
  shopOnline: "Онлайн худалдаа ",
  shopOffline: "Бодит дэлгүүр",
  shopDeviceTitle: "Бүртгэгдсэн төхөөрөмж",
  downloadShopDevices: "Төхөөрөмжийн жагсаалтыг татаж авах",
  downloadShopList: "Дэлгүүрийн жагсаалтыг татаж авах",
  shopInfo: "мэдээллийг хадгалах",
  shopReceiptName: "Баримтын нэр",
  shopTradeTypes: "Төлбөрийн төрлүүд",
  shopCreateDateTime: "Дэлгүүрийн бүртгэлийн цаг",
  qrCode: "Лицензийн QR код",
  showDisableDevice: "Бүх төрөл",
  onlineServiceId: "Онлайн үйлчилгээний дугаар",
  offlineServiceId: "Офлайн үйлчилгээний дугаар",
  storeService: "Үйлчилгээ",
  onlineService: "Онлайн худалдааны үйлчилгээ",
  offlineService: "Офлайн худалдааны үйлчилгээ",
  storeServiceId: "Үйлчилгээний дугаар",
  signKey: "SignKey",
  use: "Use",
  useRate: "Use Rate",
  give: "Give",
  giveRate: "Give Rate",
  cash: "Cash",
  wallet: "Wallet",
  creditCard: "Credit Card",
  pointsLimit: "Point award limit [Maximum amount of other companies' deposits (including cash) only]",
  singleAlertValue: "Single alert value",
  dailyAlertValue: "Daily alert value",
  singleLimit: "Single limit",
  dailyLimit: "Daily limit",
  onlineShopAbbr: "Онлайн товчлол",
  offlineShopAbbr: "Офлайн товчлол"
};
