export default {
  statisticTimeRange: "Нийт хугацаа",
  statisticByDateRange: "Статистик",
  statisticTotalCount: "Нийт тоо",
  statisticTotalAmount: "Нийт дүн",
  statisticPayCount: "Нийт төлбөрийн тоо",
  statisticPayAmount: "Нийт төлбөрийн дүн",
  statisticRefundCount: "Нийт буцаан олголтын тоо",
  statisticRefundAmount: "Нийт буцаан олголтын дүн",
  listByDate: "Огноогоор нэгтгэх",
  listByCode: "Кодоор нэгтгэх",

  listColTotalCount: "Нийт тоо",
  listColTotalAmount: "Нийт дүн",
  listColPayCount: "Төлбөрийн тоо",
  listColPayAmount: "Төлбөрийн дүн",
  listColRefundCount: "Буцаан олголтын тоо",
  listColRefundAmount: "Буцаан олголтын дүн",
  file1: {
    name: "Сар бүрийн хүснэгт",
    head1: "ашиглалтын хугацаа",
    head2: "Компани",
    bodyTitle: "Өдөр тутмын дэлгэрэнгүй",
    cell1: "Огноо",
    cell2: "Нийт гүйлгээний тоо",
    cell3: "Нийт гүйлгээний дүн",
    cell4: "Борлуулалтын тоо",
    cell5: "Борлуулалтын дүн",
    cell6: "Буцаан олголтын тоо",
    cell7: "Буцаан олголтын дүн",
    cell8: "Цэвэр дүн",
    cell9: "Борлуулалтын дүн",
    cell0: "Нийт"
  },
  file2: {
    name: "Дэлгүүрийн статистик",
    head1: "ашиглалтын хугацаа",
    head2: "Компани",
    bodyTitle: "Компанийн дэлгэрэнгүй мэдээлэл",
    cell1: "Дэлгүүрийн код",
    cell2: "Дэлгүүрийн нэр",
    cell3: "Нийт гүйлгээний тоо",
    cell4: "Нийт гүйлгээний дүн",
    cell5: "Борлуулалтын тоо",
    cell6: "Борлуулалтын дүн",
    cell7: "Буцаан олголтын тоо",
    cell8: "Буцаан олголтын дүн",
    cell9: "Цэвэр дүн",
    cella: "Борлуулалтын дүн",
    cell0: "Нийт"
  },
  file3: {
    name: "Аж ахуйн нэгжийн статистик",
    cell1: "Компанийн код",
    cell2: "Компанийн нэр"
  }
};
