export default {
  userGuid: "User Manual",
  customerSupport: "Contact US",
  starpayWorkInstallation: "How to install StarPayWorks",
  starpayQuestion: "Inquiry",
  starpayQuestionTitle: "For enquiries, please contact us using the link below.",
  starpayQeustionWindow: "Official HP Q&A",
  starpayCallSupport: "24 Hours Hotline Support",
  starpaySupportCallNo: "Phone Number : 03-6555-7777",
  starpayCallInofTitle: "Please contact us at this number for any support required.",
  BusinessTime: "Business Hours",
  EmergencyContactInfo: "Emergency Contact Info",
  printingPaperTitle: "Guidance",
  printingPaperContent:
    "The receipt paper for StarPay terminals can be used by any manufacturer as long as it is a thermal roll paper with a width of 58 mm and a diameter of 40 mm. As an example, the following products from Amazon are also available (if you have an Amazon account, you can purchase directly from the link below).",
  addManualBtn: "Add New Entry",
  manualDialogTitle: "User Manual Settings",
  categoryDialogTitle: "Category Settings",
  uploadFileBtn: "Upload File",
  labelManualName: "Manual Name ",
  labelFileName: "File ",
  labelCategoryName: "Category Name ",
  labelcategoryOrder: "Sequence Number ",
  confirmDailogTitle: "Information Notification",
  confirmDelManualCategory: "Confirm delete this Manual Category and User Manuals under this Category?",
  confirmDelManual: "Confirm delete this User Manual?",
  validCategoryNameLength: "Category Name length cannot exceed 50 characters.",
  validCategoryNameRequired: "Please eneter Category Name",
  validCategoryOrderLength: "Please enter a number between 1 and 99999",
  validCategoryOrderRequired: "Please enter Sequence Number",
  validManualNameLength: "Manual Name length cannot exceed 100 characters.",
  validManualNameRequired: "Please enter Manual Name",
  validFileNameRequired: "Please select Manual File",
  validFileSizeRequired: "The file size should not exceed 10MB",
  validFileType: "Unsupported file format",
  noData: "No data"
};
